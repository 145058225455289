<template>
  <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 514.35 302.88">
    <defs>
      <clipPath id="clip-path" transform="translate(-282.53 -388.12)">
        <path fill="none" d="M0 0h1080v1080H0z" />
      </clipPath>
    </defs>
    <g clip-path="url(#clip-path)">
      <path
        d="M621.46 481.61a81.81 81.81 0 11-23.72-70.12h-57.25A58.43 58.43 0 10587.24 505h-46.75a35.06 35.06 0 010-70.12h73.93a81.27 81.27 0 017 23.38h-81a11.69 11.69 0 000 23.37zM334.5 594.87l-7.36 9c-3.85-4.41-9.37-7.29-14.58-7.29a17.26 17.26 0 000 34.51c5.05 0 10.57-2.56 14.58-6.56l7.44 8.09c-5.92 6.08-14.49 10-22.66 10-16.74 0-29.39-12.42-29.39-28.67s12.89-28.35 29.87-28.35a31.86 31.86 0 0122.1 9.29M359.41 631.14L355 642h-13l24.34-56h13.06l23.94 56h-13.58l-4.41-10.89zm13-31.95L363.57 621h17.7zM441.49 626h-12.9v16h-12.65v-56h24.35c14.65 0 23 7 23 19.46 0 8.65-3.69 14.89-10.49 18.1L464.71 642h-14.33zm-1.2-10.57c7 0 11-3.2 11-9.61 0-6.25-4-9.37-11-9.37h-11.7v19z"
        transform="translate(-282.53 -388.12)"
      />
      <path
        d="M210.84 208.35v12.17h27.07v10.49h-27.07v12.41h30.91v10.49h-43.56v-56.05h42.68v10.49h-30.03z"
      />
      <path
        d="M582.82 591.5l-4.88 10.5c-5.93-3.52-13-5.76-17.46-5.76-4.24 0-7.12 1.52-7.12 4.56 0 10.33 29.86 4.57 29.78 24.75 0 11-9.52 16.89-21.94 16.89a39.38 39.38 0 01-24.74-8.88l5-10.33c5.77 5.12 13.94 8.4 19.86 8.4 5.21 0 8.33-1.92 8.33-5.36 0-10.57-29.87-4.41-29.87-24.27 0-10.17 8.73-16.57 21.7-16.57a40.07 40.07 0 0121.3 6.08M648.41 618.57c0 14.82-9.69 24-25.15 24s-25.46-9.2-25.46-24V586h12.65v32.59c0 8.09 5.29 13 12.81 13s12.5-4.88 12.5-13V586h12.65z"
        transform="translate(-282.53 -388.12)"
      />
      <path
        d="M383.97 197.86h12.65v56.05h-12.65zM440.03 208.59v45.32h-12.65v-45.32h-17.06v-10.73h46.84v10.73h-17.13zM483.43 208.35v12.17h27.07v10.49h-27.07v12.41h30.91v10.49h-43.56v-56.05h43.56v10.49h-30.91zM5.28 283.62v5.08h11.3v4.38H5.28v5.18h12.91v4.38H0v-23.4h17.82v4.38H5.28zM25.17 279.24h5.28v23.4h-5.28zM54.86 302.64l-11.33-15.01v15.01h-5.05v-23.4h4.81l11.37 15.05v-15.05h5.01v23.4h-4.81zM72.94 283.62v5.75h10.57v4.42H72.94v8.85h-5.28v-23.4h16.82l-.04 4.38h-11.5z"
      />
      <path
        d="M376.47 686.22l-1.84 4.55h-5.45l10.16-23.41h5.45l10 23.41h-5.65l-1.84-4.55zm5.45-13.34l-3.71 9.12h7.39zM419.46 671.08l-3.08 3.74a8.45 8.45 0 00-6.08-3 7.21 7.21 0 000 14.41 9 9 0 006.08-2.75l3.11 3.38A13.69 13.69 0 01410 691a11.89 11.89 0 01-12.27-12c0-6.75 5.39-11.83 12.47-11.83a13.3 13.3 0 019.23 3.88"
        transform="translate(-282.53 -388.12)"
      />
      <path d="M158.82 302.64v-9.22h-10.59v9.22h-5.29v-23.4h5.29V289h10.59v-9.76h5.29v23.4h-5.29z" />
      <path
        d="M476 667.36c5.14 0 8.35 2.24 8.35 5.86a5.11 5.11 0 01-4 5.14 5.75 5.75 0 014.88 5.92c0 4-3.38 6.49-8.83 6.49h-11.21v-23.41zm-5.49 9.43h5.22c2 0 3.21-1 3.21-2.61s-1.24-2.5-3.21-2.5h-5.22zm0 9.66h5.22c2.51 0 4-1 4-2.84s-1.5-2.74-4-2.74h-5.22z"
        transform="translate(-282.53 -388.12)"
      />
      <path d="M214.59 283.62v5.08h11.29v4.38h-11.29v5.18h12.9v4.38H209.3v-23.4h17.82v4.38h-12.53z" />
      <path
        d="M534.46 669.67l-2 4.38a16.34 16.34 0 00-7.29-2.41c-1.77 0-3 .64-3 1.91 0 4.31 12.47 1.9 12.43 10.33 0 4.58-4 7-9.16 7a16.44 16.44 0 01-10.33-3.71l2.11-4.31a13.87 13.87 0 008.29 3.51c2.17 0 3.48-.8 3.48-2.24 0-4.41-12.47-1.84-12.47-10.13 0-4.25 3.64-6.92 9.06-6.92a16.71 16.71 0 018.89 2.54M558.59 669.67l-2 4.38a16.38 16.38 0 00-7.29-2.41c-1.77 0-3 .64-3 1.91 0 4.31 12.47 1.9 12.44 10.33 0 4.58-4 7-9.16 7a16.42 16.42 0 01-10.33-3.71l2.1-4.31a13.87 13.87 0 008.3 3.51c2.17 0 3.47-.8 3.47-2.24 0-4.41-12.47-1.84-12.47-10.13 0-4.25 3.65-6.92 9.06-6.92a16.69 16.69 0 018.89 2.54"
        transform="translate(-282.53 -388.12)"
      />
      <path d="M288.03 283.62v5.08h11.3v4.38h-11.3v5.18h12.91v4.38h-18.19v-23.4h17.82v4.38h-12.54z" />
      <path
        d="M601.12 684.08h-5.38v6.69h-5.29v-23.41h10.17c6.11 0 9.59 2.95 9.59 8.13 0 3.61-1.54 6.22-4.38 7.55l5 7.73h-6zm-.5-4.41c2.9 0 4.58-1.34 4.58-4s-1.68-3.91-4.58-3.91h-4.88v7.93zM638 667.36c6 0 9.36 2.95 9.36 8.13 0 5.45-3.41 8.59-9.36 8.59h-4.65v6.69h-5.28v-23.41zm-4.65 12.31h4.38c2.94 0 4.62-1.34 4.62-4s-1.68-3.91-4.62-3.91h-4.38z"
        transform="translate(-282.53 -388.12)"
      />
      <path
        d="M376.32 283.62v5.75h10.56v4.42h-10.56v8.85h-5.28v-23.4h16.81l-.03 4.38h-11.5zM409.08 298.03v4.61h-15.31v-23.4h5.28v18.79h10.03zM420.35 283.62v5.08h11.29v4.38h-11.29v5.18h12.9v4.38h-18.19v-23.4h17.82v4.38h-12.53z"
      />
      <path
        d="M743.06 679v9a17.34 17.34 0 01-9.49 3c-7.09 0-12.47-5.15-12.47-11.93s5.48-11.87 12.77-11.87a14.7 14.7 0 019.39 3.41l-3 3.77a9.82 9.82 0 00-6.42-2.6 7.31 7.31 0 100 14.61 10.78 10.78 0 004.58-1.27V679z"
        transform="translate(-282.53 -388.12)"
      />
      <path
        d="M473.26 283.62v5.08h11.3v4.38h-11.3v5.18h12.91v4.38h-18.19v-23.4h17.82v4.38h-12.54zM509.54 302.64l-11.34-15.01v15.01h-5.05v-23.4h4.82l11.36 15.05v-15.05h5.02v23.4h-4.81z"
      />
    </g>
  </svg>
</template>
