import { logInfo } from '@/common/utils'

const noop = () => {
}

interface undoable<T = unknown, U = unknown> {
  run: (action: CallableFunction, undo: CallableFunction, finallyFn: CallableFunction) => Promise<T>
  undo: () => Promise<U>
}

// Undoable allows an action to be delayed and undone in a given timeframe.
export class Undoable<T, U> implements undoable {
  private timeout: number | undefined
  private readonly delay: number

  private actionFn: CallableFunction = noop
  private undoFn: CallableFunction = noop
  private finallyFn: CallableFunction = noop

  public constructor (delay = 3000) {
    this.delay = delay
  }

  private reset () {
    window.clearTimeout(this.timeout)
    this.actionFn = noop
    this.undoFn = noop
    this.finallyFn = noop
  }

  public async run (actionFn: CallableFunction, undoFn: CallableFunction, finallyFn: CallableFunction = noop): Promise<T> {
    window.clearTimeout(this.timeout)

    this.actionFn = actionFn
    this.undoFn = undoFn
    this.finallyFn = finallyFn

    return new Promise((resolve, reject) => {
      this.timeout = setTimeout(async () => {
        try {
          const action = await this.actionFn()
          resolve(action)
        } catch (e) {
          logInfo('undoing', e)
          this.undo()
          reject(e)
        } finally {
          await this.finallyFn()
          this.reset()
        }
      }, this.delay)
    })
  }

  public async undo (): Promise<U> {
    return new Promise(async (resolve, reject) => {
      window.clearTimeout(this.timeout)

      try {
        const undo = await this.undoFn()
        resolve(undo)
      } catch (e) {
        reject(e)
      } finally {
        await this.finallyFn()
        this.reset()
      }
    })
  }
}