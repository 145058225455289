<script setup lang="ts">
  import { computed } from 'vue'
  import { useStore } from '@/store'

  const store = useStore()

  const mutedUntil = computed(() => store.getters['user/mutedUntil'])
  const mutedUntilTime = computed(() => store.getters['user/mutedUntilTime'])

  const mutedUntilTimeHours = computed(() => mutedUntilTime.value.split(':')[0])
  const mutedUntilTimeMinutes = computed(() => mutedUntilTime.value.split(':')[1])
</script>

<template>
  <template v-if="mutedUntil > 120">
    {{ $t('mute.states.muted_permanent') }}
  </template>
  <template v-else>
    <i18n-t keypath="mute.states.muted_for">
      <span class="whitespace-nowrap">
        <strong>
          {{ $t('common.time_format', [mutedUntilTimeHours, mutedUntilTimeMinutes]) }}
        </strong>
        <span>
          ({{ mutedUntil }} {{ $t('common.minutes_short') }})
        </span>
      </span>
    </i18n-t>
  </template>
</template>