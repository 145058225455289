<script setup lang="ts">
  import { mdiTranslate } from '@mdi/js'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import Modal from '@/components/modal/Modal.vue'
  import { ref } from 'vue'
  import { Locale } from '@/types'
  import { loadLocale } from '@/bootstrap/i18n'

  const modalVisible = ref(false)
  const locales = ref([
    { code: Locale.De, name: 'Deutsch' },
    { code: Locale.En, name: 'English' },
    { code: Locale.Fr, name: 'Français' },
    { code: Locale.It, name: 'Italiano' },
  ])

  async function setLocale (locale: Locale) {
    modalVisible.value = false

    await loadLocale(locale)
  }
</script>

<template>
  <div>
    <button
      v-wave
      class="size-9 rounded-sm flex items-center justify-center text-csBlue-600"
      @click.prevent="modalVisible = true"
    >
      <MdiIcon :icon="mdiTranslate" class="size-5" />
    </button>

    <Modal :centerTitle="true" class="text-gray-800" :title="$t('common.actions.select_language')" :visible="modalVisible">
      <ul class="grid grid-cols-2 gap-2">
        <li v-for="locale in locales" :key="locale.code">
          <button v-wave class="block w-full font-semibold p-2 bg-gray-100 text-center rounded" @click.prevent="setLocale(locale.code)">
            {{ locale.name }}
          </button>
        </li>
      </ul>
    </Modal>
  </div>
</template>