<script setup lang="ts">
  import { checkInClient, checkInClientThrottled } from '@/api/requests'
  import { setSentryContext } from '@/bootstrap/sentry'
  import { logError, logInfo, setVH, throttleWithBackoff } from '@/common/utils'
  import { useStore } from '@/store'
  import axios from 'axios'
  import DebugOverlay from '@/components/debug/DebugOverlay.vue'
  import { computed, onBeforeUnmount, onMounted, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { SplashScreen } from '@capacitor/splash-screen'
  import { useConfig } from '@/config'
  import { Call } from '@/types'
  import { differenceInSeconds, parseISO } from '@/common/time'
  import { isEmergency, useGroupedCalls } from '@/common/call.api'
  import { useDocumentVisibility, useNow } from '@vueuse/core'
  import { ifNative, State } from '@/capacitor'
  import { CallListViewMode, PromptPermissionsMode } from '@/store/ui.state'
  import { logoutUser } from '@/common/user.api'
  import PermissionPrompter from '@/components/permissions/PermissionPrompter.vue'
  import { Capacitor } from '@capacitor/core'
  import { useI18n } from 'vue-i18n'

  const store = useStore()
  const i18n = useI18n()
  const router = useRouter()

  const clientState = computed(() => store.state.client)
  const debug = computed(() => store.state.ui.debug)

  // Redirect to the calls view if a new critical alerts is received
  const latestCriticalAlert = computed(() => store.getters['alert/latestCriticalNotConfirmedAlert'])
  watch(() => latestCriticalAlert.value, newValue => {
    if (newValue) {
      store.commit('ui/setCallListViewMode', CallListViewMode.Priority)
    }
  })

  const promptPermissions = computed(() => store.state.ui.promptPermissions >= PromptPermissionsMode.Prompt && Capacitor.isNativePlatform())
  const isMuted = computed(() => store.getters['user/mutedUntil'] !== null)
  const visibility = useDocumentVisibility()
  const now = useNow()
  const { calls } = useGroupedCalls(store)

  // Trigger the call queue if new calls are received.
  const triggerCallQueueThrottled = throttleWithBackoff(async (newValue, oldValue) => {
    // Don't do anything for muted clients.
    if (isMuted.value) {
      return
    }

    store.commit('ui/setProcessingCallQueue', true)

    // If there are emergency calls always open the emergency view.
    const emergencyCall = newValue.find((call: Call) => isEmergency(call))
    if (emergencyCall && differenceInSeconds(now.value, parseISO(emergencyCall.opened_at)) < 8 && visibility.value === 'visible') {
      await router.push({ name: 'emergency', params: { id: emergencyCall.id } })
      store.commit('ui/setProcessingCallQueue', false)
      return
    }

    // Otherwise, only open the call queue if there are new calls, and the current route allows the popup.
    if (!store.state.ui.currentRoute.meta.allowCallQueuePopup) {
      store.commit('ui/setProcessingCallQueue', false)
      return
    }

    const oldIDs = oldValue.map((call: Call) => call.id)
    const newIDs = newValue.map((call: Call) => call.id)

    const added = newIDs.filter((id: number) => !oldIDs.includes(id))

    if (added.length > 0 && !isMuted.value) {
      await router.push({ name: 'callQueue', query: { popup: 'true' } })
    }
    store.commit('ui/setProcessingCallQueue', false)
  }, 50, 2000)

  watch(() => calls.value['new'], (newValue, oldValue) => {
    triggerCallQueueThrottled(newValue, oldValue)
  }, { deep: true })

  function beforeUnload (e: BeforeUnloadEvent) {
    if (store.state.call.pendingActions === 0) {
      return
    }
    e.preventDefault()
    return e.returnValue = 'Es gibt noch pendente Änderungen, die verloren gehen, wenn Sie die Seite neu laden.'
  }

  function registerGlobalApplicationEvents () {
    window.addEventListener('resize', setVH)
    window.addEventListener('beforeunload', beforeUnload)

    ifNative(() => {
      // Log the user out when the backend sends a logout event (i.e. due to a change of the charging state).
      // TODO: This can be removed once all servers are updated to version 5.205 or higher.
      State.addListener('onLogout', async () => {
        logInfo('User has been logged out by the native backend')

        const closeSnackbar = await store.dispatch('ui/showSnackbar', {
          message: 'Du wurdest abgemeldet weil das Gerät aufgeladen wird.'
        })

        setTimeout(closeSnackbar, 8000)

        await store.dispatch('user/clearUser')
        await router.push({ name: 'login' })
      })

      // Relay snackbar messages from the native backend to the UI.
      State.addListener('onSnackbar', async (payload) => {
        const closeSnackbar = await store.dispatch('ui/showSnackbar', payload)

        setTimeout(closeSnackbar, payload.timeout ?? 4000)
      })

      // Force a client check-in if the backend requests it.
      State.addListener('onForceCheckIn', async () => {
        logInfo('Forcing client check-in request from native backend')
        await checkInClient({
          messaging_token: clientState.value.client.messaging_token,
        }, router, store)
      })

      // Force a client logout if the backend requests it.
      State.addListener('onForceLogout', async () => {
        logInfo('Forcing client logout request from native backend')
        await logoutUser(store)
        await router.push({ name: 'login' })
      })

      // Prompt the user to run any intents that are triggered by a scanned NFC tags.
      State.addListener('NfcTagScanned', async (payload) => {
        logInfo('NFC Tag scanned', payload)

        let response: any
        try {
          response = JSON.parse(payload.response)
        } catch (e) {
          logError('Failed to parse NFC tag response', e)
        }

        if (response.intent) {
          await store.dispatch('ui/showSnackbar', {
            message: response.intent.message,
            action: response.intent.action,
            timeout: 12000,
            actionFn: () => {
              const a = document.createElement('a')
              a.href = response.intent.url
              a.target = '_blank'
              a.click()
              a.remove()
            }
          })
        } else {
          await store.dispatch('ui/showSnackbar', {
            message: i18n.t('common.action_success'),
            timeout: 4000
          })
        }
      })
    })
  }

  function unregisterGlobalApplicationEvents () {
    window.removeEventListener('resize', setVH)
    window.removeEventListener('beforeunload', beforeUnload)
    State.removeAllListeners()
  }

  onMounted(async () => {
    registerGlobalApplicationEvents()

    const config = await useConfig()
    if (!config.backend) {
      await SplashScreen.hide()
      return router.push({ name: 'register' })
    }

    // Send the current config to the backend and receive the updated license information.
    try {
      const client = clientState.value?.client
      if (!client?.id) {
        return router.push({ name: 'register' })
      }

      // A client without a current user is not logged in, so redirect to the login view.
      if (!client?.current_user_id) {
        logInfo('Client has no user attached, redirecting to login')
        await store.dispatch('user/clearUser')
        return router.push({ name: 'login' })
      }

      if (store.state.user.user.id) {
        const response = await checkInClientThrottled({ messaging_token: clientState.value.client.messaging_token }, router, store)
        await setSentryContext(response.license, store.state)

        store.commit('ui/promptPermissions')
      }
    } catch (e) {
      // A 401 error means that this registration is no longer valid.
      // We can clear it and show the registration screen again.
      if (axios.isAxiosError(e) && e.response && e.response.status === 401) {
        await store.dispatch('client/clearClient')
        return router.push({ name: 'register', query: { state: 'invalid' } })
      }
      return logError('failed to do initial client check-in', e)
    } finally {
      setTimeout(() => SplashScreen.hide(), 100)
    }
  })

  onBeforeUnmount(() => {
    unregisterGlobalApplicationEvents()
  })
</script>

<template>
  <RouterView />

  <PermissionPrompter v-if="promptPermissions" />

  <DebugOverlay v-if="debug" />
</template>

<style lang="stylus">
  html
    // Disable touch highlight in Chrome
    -webkit-tap-highlight-color rgba(255, 255, 255, 0)

  // Hide Android Scrollbars (they are
  *::-webkit-scrollbar
    display none

  #app
    @apply bg-gray-50 font-sans

  button
    @apply outline-none

  // Screen height, includes browser address bar dynamically.
  .h-screen-fixed
    min-height: calc(100 * var(--vh))
    max-height: calc(100 * var(--vh))

  // Same as .h-screen-fixed, but without the AppBarTop height.
  .h-screen-header
    min-height: calc(100 * var(--vh) - 56px)
    max-height: calc(100 * var(--vh) - 56px)

  .min-h-screen-header
    min-height: calc(100 * var(--vh) - 56px)

  @media screen and (prefers-reduced-motion: reduce), (update: slow)
    animation-duration 0.001ms !important
    animation-delay 0.001ms !important
    animation-iteration-count 1 !important
    transition-duration 0.001ms !important
    transition-delay 0.001ms !important

  // Transitions
  .collapse-enter-from,
  .collapse-leave-to
    overflow hidden
    transform translateX(100%)
    opacity 0

  .collapse-enter-to,
  .collapse-leave
    transform translateX(0)
    opacity 1

  .collapse-enter-active,
  .collapse-leave-active
    transition .3s cubic-bezier(0.4, 0.0, 0.2, 1)
    transition-property transform, opacity
</style>
