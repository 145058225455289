import { State } from '@/store'
import CallDetailView from '@/views/CallDetailView.vue'
import CallQueueView from '@/views/CallQueueView.vue'
import CallListView from '@/views/CallListView.vue'
import LoginView from '@/views/LoginView.vue'
import RegisterView from '@/views/RegisterView.vue'
import NotFoundView from '@/views/NotFoundView.vue'
import { RouteRecordRaw } from 'vue-router'
import { Store } from 'vuex'
import MainLayout from './layouts/MainLayout.vue'
import { logError } from '@/common/utils'
import EmergencyView from '@/views/EmergencyView.vue'
import AlertView from '@/views/AlertView.vue'
import SubscriptionView from '@/views/SubscriptionView.vue'
import { logoutUser } from '@/common/user.api'
import SOSTriggerView from '@/views/SOSTriggerView.vue'
import SOSView from '@/views/SOSView.vue'

export const routes = (store: Store<State>): RouteRecordRaw[] => ([
  {
    path: '/',
    name: 'MainLayout',
    component: MainLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'callList',
        component: CallListView,
        meta: { footer: true, allowCallQueuePopup: true }
      },
      {
        path: '/subscription',
        name: 'subscription',
        component: SubscriptionView,
        meta: { backButton: { name: 'callList' }, footer: true }
      },
      {
        path: '/call/queue',
        name: 'callQueue',
        component: CallQueueView,
        meta: {
          backButton: { name: 'callList' }
        }
      },
      {
        path: '/call/:id',
        name: 'callDetail',
        component: CallDetailView,
        meta: {
          backButton: { name: 'callList' },
          allowCallQueuePopup: true
        }
      },
      {
        path: '/register/:token?',
        name: 'register',
        component: RegisterView,
        props: route => ({ state: route.query.state ?? '' }),
        beforeEnter: async (from, to, next) => {
          // Redirect home if already registered.
          if (store.state.client.client.id) {
            next({ name: 'callList' })
            return
          }
          next()
        }
      },
      {
        name: 'logout',
        path: '/logout',
        redirect: '',
        beforeEnter: async (from, to, next) => {
          try {
            await logoutUser(store)
          } catch (e) {
            logError('failed to log out user locally', e)
          } finally {
            next({ name: 'login' })
          }
        }
      },
      { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: async (from, to, next) => {
      // Redirect home if already logged in.
      if (store.state.user.user.id) {
        next({ name: 'callList' })
        return
      }
      next()
    }
  },
  {
    path: '/emergency/:id',
    name: 'emergency',
    component: EmergencyView,
  },
  {
    path: '/sos/trigger',
    name: 'sos-trigger',
    component: SOSTriggerView,
  },
  {
    path: '/sos/:username/:call_id?',
    name: 'sos',
    component: SOSView,
  },
  {
    path: '/alert/:id',
    name: 'alert',
    component: AlertView,
  },
])

