<script setup lang="ts">
  import { mdiBellOff, mdiPhoneOutgoing, mdiPlusCircle } from '@mdi/js'
  import { computed, PropType, toRefs } from 'vue'
  import { Call, CallHandlingMode } from '@/types'
  import { useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { CallActionEvent, useCallStates } from '@/common/call.api'

  import { ActionConfig } from '@/common/swipe.api'
  import { formatSecondsAsMinutes, formatTime, parseISO } from '@/common/time'
  import SwipeableItem from '@/components/ui/SwipeableItem.vue'
  import ActionableListItem from '@/components/ui/ActionableListItem.vue'
  import CallStatusIndicator from '@/components/calls/CallStatusIndicator.vue'
  import { useI18n } from 'vue-i18n'

  const emit = defineEmits<{
    (e: CallActionEvent, call: Call): void
  }>()

  const props = defineProps({
    call: {
      type: Object as PropType<Call>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  })

  const i18n = useI18n()
  const router = useRouter()
  const store = useStore()
  const { call } = toRefs(props)

  const callHandlingMode = computed(() => store.state.user.group.call_handling_mode)

  // Call age in Minutes
  const callAgeString = computed(() => {
    return call.value.closed_at === null ? formatSecondsAsMinutes(callAgeInSeconds.value) : formatTime(parseISO(call.value.opened_at))
  })

  const isActionable = computed(() => !props.readOnly && action.value.button.event !== '')

  //
  // Call Actions
  //
  const {
    markEscalated,
    callAgeInSeconds,
    escalationPercentage,
    isAcceptedByMyself,
    isMutedByMyself,
    isAccepted,
    isPresence,
    isClosed,
  } = useCallStates(store, call)

  // contextLine is the second line of the Call card.
  const contextLine = computed(() => {
    const parts = []
    if (props.call.call_type_text) {
      parts.push(props.call.call_type_text)
    }
    if (props.call.position) {
      parts.push(props.call.position)
    }
    return parts.join(', ')
  })

  //
  // Swipe Gesture
  //

  // action defines the button/swipe label and action.
  const action = computed<ActionConfig>(() => {
    // Call has been handled.
    if (isPresence.value || isClosed.value || isMutedByMyself.value || (isAccepted.value && !isAcceptedByMyself.value)) {
      return { button: { label: '', event: '', icon: '' }, swipe: { event: '', label: '' } }
    }

    // Own calls, allow to give back.
    if (props.call.accepted_by_user_id === store.state.user.user.id) {
      let buttonForOwn: ActionConfig['button']
      if (props.call.callback_number && store.state.client.client.phone_number && store.state.app.features.phone_call_support) {
        buttonForOwn = { label: i18n.t('common.actions.call_back'), icon: mdiPhoneOutgoing, event: CallActionEvent.CallBack }
      } else {
        buttonForOwn = { label: '', event: '', icon: '' }
      }

      return {
        button: buttonForOwn,
        swipe: { label: i18n.t('common.actions.release'), event: CallActionEvent.Release }
      }
    }

    // In RejectOnly mode, only allow to reject.
    if (callHandlingMode.value === CallHandlingMode.RejectOnly) {
      return {
        swipe: { label: i18n.t('common.actions.mute'), event: CallActionEvent.Mute },
        button: { label: i18n.t('common.actions.mute'), icon: mdiBellOff, event: CallActionEvent.Mute }
      }
    }

    // All other calls can be accepted.
    return {
      swipe: { label: i18n.t('common.actions.reject'), event: CallActionEvent.Reject },
      button: { label: i18n.t('common.actions.accept'), icon: mdiPlusCircle, event: CallActionEvent.Accept }
    }
  })

  function swipeCallback () {
    if (action.value.swipe.event) {
      emit(action.value.swipe.event, props.call)
    }
  }

  function onClick () {
    if (action.value.button.event) {
      emit(action.value.button.event, props.call)
    }
  }

  const style = computed(() => {
    const styles: Record<string, string | number> = {
      'border-left-width': '4px'
    }
    // Escalated calls add their own border color.
    if (!markEscalated.value) {
      // Add the call type's color as the bottom border.
      styles['border-left-color'] = call.value.call_type_color
    }
    return styles
  })
</script>

<template>
  <SwipeableItem
    :id="`call-list-item-${call.id}`"
    :swipeLabel="action.swipe.label"
    :readOnly="!isActionable"
    @swiped="swipeCallback"
    @click="router.push({ name: 'callDetail', params: { id: props.call?.id } })"
  >
    <ActionableListItem
      class="call"
      :class="{'border-yellow-700': markEscalated}"
      :style="isClosed ? {} : style"
      :urgent="markEscalated"
      :accentColor="call.call_type_color"
      :iconLabel="callAgeString"
      :title="call.text_short"
      :actionIcon="action.button.icon"
      :actionLabel="action.button.label"
      :isClosed="call.closed_at !== null"
      :readOnly="!isActionable"
      @action="onClick"
    >
      <!-- Escalation progress indicator -->
      <div
        v-if="!isAccepted && !isClosed && escalationPercentage > 0 && escalationPercentage < 1"
        class="z-40 transition-transform duration-1000 ease-linear h-[2px] w-full absolute left-[-2px] bottom-[-1px] origin-left"
        :class="{'!bg-yellow-700': markEscalated}"
        :style="{ transform: `scaleX(${escalationPercentage})`, background: call.call_type_color }"
      />

      <template #title>
        <div class="flex items-center justify-between">
          <div class="flex-1">
            {{ call.text_short }}
          </div>
          <div v-if="call.call_source_char" class="flex-0">
            <div class="text-sm font-bold text-right">
              {{ call.call_source_char }}
            </div>
          </div>
        </div>
      </template>

      <template #text>
        {{ contextLine }}
      </template>

      <template #meta>
        <CallStatusIndicator :call="call" />
      </template>
    </ActionableListItem>
  </SwipeableItem>
</template>

<style scoped lang="stylus">
  .bg-pulse
    @apply relative

    &:after
      content ""
      animation pulse 1s infinite
      animation-direction alternate
      @apply absolute z-0 inset-0 bg-white

  .shake
    animation shake 2s infinite

  @keyframes pulse
    from
      opacity 0
    to
      opacity .1

  @keyframes shake
    0%, 50%
      transform rotate(0)
    10%, 20%, 30%, 40%
      transform rotate(-4deg)
    5%, 15%, 25%, 35%, 45%
      transform rotate(4deg)
</style>
