<template>
  <div class="flex-0 mb-2">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DrawerListFooter',
    setup () {
    }
  })
</script>
