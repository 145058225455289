<script lang="ts" setup>
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import Callout from '@/components/layout/Callout.vue'
  import ErrorDetails from '@/components/debug/ErrorDetails.vue'
  import { ref } from 'vue'
  import { mdiConnection } from '@mdi/js'

  const emit = defineEmits(['retry'])
  defineProps({
    error: {
      default: () => ({}),
      type: Object,
    }
  })

  const debuggingClicks = ref(0)
  const detailsVisible = ref(false)

  function reload () {
    document.location.reload()
  }

  function retry () {
    emit('retry')
    debuggingClicks.value = 0
  }
</script>

<template>
  <Callout :icon="mdiConnection" title="Verbindungsunterbruch" @click.prevent="debuggingClicks++">
    <p>
      Stelle sicher, dass du mit dem richtigen WLAN verbunden bist und du dich im Empfangsbereich befindest.
    </p>

    <p v-if="debuggingClicks >= 5" class="text-gray-400 text-xs">
      <a href="#" class="text-inherit border-b border-current" @click="detailsVisible = true">
        Details anzeigen
      </a>
    </p>

    <template #actions>
      <ContainedButton
        class="bg-csBlue-800 mt-8"
        @click.prevent="retry"
      >
        Erneut versuchen
      </ContainedButton>

      <a
        v-if="detailsVisible"
        href="#"
        class="mt-4 text-xs border-b border-csBlue-500 text-csBlue-500"
        @click.prevent="reload"
      >
        App neu laden
      </a>
    </template>

    <div class="w-[90vw] !mt-4">
      <ErrorDetails :visible="detailsVisible" :errorDetails="Object.keys(error ?? {}).length ? error : {}" @hide="detailsVisible = false" />
    </div>
  </Callout>
</template>