import { checkInClient } from '@/api/requests'
import { ifWeb, WebSocket as CapacitorWebSocket } from '@/capacitor'
import { logError, logInfo } from '@/common/utils'
import { State } from '@/store'
import { Alert, Call, NotificationData } from '@/types'
import { logoutUser } from '@/common/user.api'
import { Store } from 'vuex'
import { Router } from 'vue-router'

type WebSocketMessage = {
  type: 'CALLS',
  data: Call[]
} | {
  type: 'NOTIFICATION',
  data: NotificationData,
} | {
  type: 'ALERTS',
  data: Alert[],
} | {
  type: 'FORCE_CHECKIN',
  data: null,
} | {
  type: 'FORCE_LOGOUT',
  data: null,
} | {
  type: 'TRIGGER_SOS',
  data: {
    user: {
      id: string,
      name: string
      status_related_call_id?: string
    }
  },
} | {
  type: 'CANCEL_SOS'
}

const log = (message: string, ...args: any[]) => logInfo('[WebSocket] ' + message, ...args)

// handlers contains the callback functions for specific web socket events.
export const handlers = {
  onOpen (store: Store<State>) {
    log('WebSocket connected')
    store.commit('ui/setWebsocketConnected', true)
    store.commit('ui/setWebsocketConnectionErrorMessage', '')
  },

  onClose (store: Store<State>, reason: any) {
    log('WebSocket disconnected: ', reason)
    store.commit('ui/setWebsocketConnected', false)
    if (reason) {
      store.commit('ui/setWebsocketConnectionErrorMessage', reason)
    }
  },

  onError (store: Store<State>, error: any) {
    logError('WebSocket error', JSON.stringify(error))
    if (error) {
      store.commit('ui/setWebsocketConnectionErrorMessage', error)
    }
  },

  async onMessage (store: Store<State>, router: Router, payload: { message: string }) {
    try {
      const message: WebSocketMessage = JSON.parse(payload.message)

      logInfo('message received', payload.message.substring(0, 100) + '...')

      switch (message.type) {
      case 'CALLS':
        store.commit('call/set', { calls: message.data })
        break
      case 'ALERTS':
        store.commit('alert/setAlerts', message.data)
        break
      case 'NOTIFICATION':
        // Do nothing, handled by the native backend.
        break
      case 'TRIGGER_SOS':
        await router.push({ name: 'sos', params: { username: message.data.user.name, call_id: message.data.user.status_related_call_id } })
        break
      case 'CANCEL_SOS':
        if (router.currentRoute.value.name === 'sos') {
          await router.push({ name: 'callList' })
        }
        break
      case 'FORCE_CHECKIN':
        const user = store.state.user.user
        if (!user) {
          break
        }
        // Randomly delay the request so not all clients check in at the same time.
        await delay(async () => {
          log('Running forced check-in')
          await checkInClient({}, router, store)
        }, randomNumber(0, 10000))
        break
      case 'FORCE_LOGOUT':
        // The native code will handle the logout process.
        ifWeb(async () => {
          log('Received force logout message')
          await logoutUser(store)
        })
        break
      default:
        logInfo('unhandled message type received', message)
      }
    } catch (error) {
      logError('Error while handling websocket message', error)
      store.commit('ui/setWebsocketConnectionErrorMessage', error)
    }
  }
}

// subscribe subscribes to websocket events received by the native app code.
export async function subscribe (store: Store<State>, router: Router) {
  await unsubscribe()

  CapacitorWebSocket.addListener('onOpen', () => handlers.onOpen(store))
  CapacitorWebSocket.addListener('onClose', data => handlers.onClose(store, data))
  CapacitorWebSocket.addListener('onError', data => handlers.onError(store, data))
  CapacitorWebSocket.addListener('onMessage', data => handlers.onMessage(store, router, data))

  ifWeb(async () => {
    await CapacitorWebSocket.setStore(store)
    await CapacitorWebSocket.setRouter(router)
  })

  // The connection is established before the plugin is registered.
  // This is why we cannot rely on the events from above and have
  // to fetch the current connection state manually here.
  const result = await CapacitorWebSocket.getState()
  if (result) {
    store.commit('ui/setWebsocketConnected', result.connected)
  }
  // Start the WebSocket service if it is not running.
  if (!result || !result.serviceRunning || !result.connected) {
    await CapacitorWebSocket.startService()
  }
}

export async function unsubscribe () {
  await CapacitorWebSocket.removeAllListeners()
}

// unsubscribe unsubscribes from all websocket events.
export async function reconnect () {
  await CapacitorWebSocket.startService()
}

const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min

function delay<T> (fn: () => T, ms: number): Promise<T> {
  return new Promise(resolve => setTimeout(() => resolve(fn()), ms))
}
