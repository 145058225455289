import { State } from '@/store'
import axios, { AxiosInstance } from 'axios'
import { setNow } from '@/common/time'
import { Router } from 'vue-router'
import { Store } from 'vuex'
import { WifiManager } from '@/capacitor'
import { logWarning } from '@/common/utils'
import { Locale } from '@/types'

let http: AxiosInstance

// Use the ?storagePartition parameter as cookie name prefix.
// This allows us to run a demo version of the app inside
// the same browser but use different session cookies for each session.
let useCookieName = ''
if (window.CS_DEMO?.storagePartition) {
  useCookieName = window.CS_DEMO.storagePartition
}

const getCookieName = () => window.CS_DEMO?.demoClient ? useCookieName : ''

export function setupAxios (store: Store<State>, router: Router, locale: Locale, backend: string, version: string) {
  let appPrefix = '/app'

  if (import.meta.env.DEV) {
    appPrefix = ''
  }

  const apiPrefix = '/api'

  http = axios.create({
    baseURL: `${backend}${appPrefix}${apiPrefix}`,
    headers: { 'X-CS-Version': version },
    timeout: 7000,
  })

  http.interceptors.request.use(async config => {
    if (config.headers) {
      if (store.state.client.client.session_id) {
        config.headers['X-CS-Session-ID'] = store.state.client.client.session_id
      }

      if (store.state.client.client.id && store.state.client.client.secret) {
        config.headers['X-CS-Client'] = store.state.client.client.id
      }

      const cookieName = getCookieName()
      if (cookieName) {
        config.headers['X-CS-Cookie-Name'] = cookieName
      }

      if (locale) {
        config.headers['Accept-Language'] = locale
      }

      // Add Wifi signal strength to the request headers on some requests.
      if (Math.random() < 0.015) {
        try {
          config.headers['X-CS-Signal-Level'] = (await WifiManager.getWifiInfo()).percentage
        } catch (e) {
          // Log, but ignore.
          logWarning('Failed to get wifi signal strength', e)
        }
      }
    }

    return config
  })

  http.interceptors.response.use((response) => {
    // Update the local clock with the server timestamp.
    if (response.headers.hasOwnProperty('x-cs-time')) {
      setNow(Number(response.headers['x-cs-time']) * 1000)
    }

    return response
  }, async function (error) {
    if (!error.response) {
      throw error
    }

    const response = error.response
    // Client registration was not found.
    if (response?.status === 511) {
      await store.dispatch('client/clearClient')
      await router.push({ name: 'register' })
      return
    }
    // User session expired.
    if (response?.status === 401) {
      await store.dispatch('user/clearUser')
      await router.push({ name: 'login' })
      return
    }
    throw error
  })
}

export { http }
