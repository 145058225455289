<script setup lang="ts">
  import { computed, PropType, toRefs } from 'vue'
  import { useCallStates } from '@/common/call.api'
  import { mdiBellOff, mdiCheckAll, mdiCheckboxMarkedCircleOutline, mdiClockAlertOutline, mdiCloseBox, mdiUndo } from '@mdi/js'
  import { Call } from '@/types'
  import { formatSecondsAsMinutes, formatSecondsAsString, formatTime, parseISO } from '@/common/time'
  import { useStore } from '@/store'
  import MdiIcon from '@/components/ui/MdiIcon.vue'

  const props = defineProps({
    call: {
      type: Object as PropType<Call>,
      required: true
    }
  })

  const store = useStore()
  const { call } = toRefs(props)

  // Call duration in "2m 4s" format
  const callDurationString = computed(() => formatSecondsAsString(callAgeInSeconds.value))
  const releaseCountdown = computed(() => formatSecondsAsMinutes(Number(secondsUntilRelease.value)))

  //
  // Call Actions
  //
  const {
    callAgeInSeconds,
    secondsUntilRelease,
    isMutedByMyself,
    isRejectedByMyself,
    isReleasedByMyself,
    isAcceptedByMyself,
    isRejectOnlyCallHandlingMode,
    markEscalated,
    isAccepted,
    isPresence,
    isClosed,
    isUrgent,
  } = useCallStates(store, call)

</script>

<template>
  <div class="font-normal">
    <!-- Closed Call or presence -->
    <div v-if="isClosed || isPresence" class="flex items-center">
      <div class="mr-1">
        <MdiIcon width="14" height="14" :icon="mdiCheckAll" type="mdi" />
      </div>
      <div class="truncate">
        <template v-if="isPresence">
          {{ call.accepted_by_username ? call.accepted_by_username : $t('common.someone') }}
          {{ call.closed_at ? $t('common.was') : $t('common.is') }}
          {{ $t('calllist.states.present') }}
        </template>
        <template v-else-if="isAcceptedByMyself">
          {{ $t('calllist.states.closed.by_myself') }}
        </template>
        <template v-else-if="call.accepted_by_username">
          {{ $t('calllist.states.closed.by_others', { name: call.accepted_by_username }) }}
        </template>
        <template v-else-if="call.closed_at !== null">
          {{ $t('calllist.states.closed.by_unknown', { time: formatTime(parseISO(call.closed_at)), duration: callDurationString }) }}
        </template>
      </div>
    </div>

    <!-- Released Call -->
    <div v-else-if="isReleasedByMyself" class="flex items-center">
      <div class="mr-1">
        <MdiIcon width="14" height="14" :icon="mdiUndo" />
      </div>
      <div>
        {{ $t('calllist.states.released') }}
      </div>
    </div>

    <!-- Muted Call -->
    <div v-else-if="isMutedByMyself" class="flex items-center">
      <div class="mr-1">
        <MdiIcon width="14" height="14" :icon="mdiBellOff" />
      </div>
      <div>
        {{ $t('calllist.states.muted') }}
      </div>
    </div>

    <!-- Rejected Call -->
    <div v-else-if="isRejectedByMyself" class="flex items-center">
      <div class="mr-1">
        <MdiIcon width="14" height="14" :icon="mdiCloseBox" />
      </div>
      <div>
        {{ $t('calllist.states.rejected') }}
      </div>
    </div>

    <!-- Accepted Call -->
    <div v-else-if="isAcceptedByMyself || isAccepted" class="flex items-center">
      <div class="mr-1">
        <MdiIcon width="14" height="14" :icon="mdiCheckboxMarkedCircleOutline" />
      </div>
      <div class="truncate">
        <template v-if="isAcceptedByMyself">
          <template v-if="secondsUntilRelease === null || secondsUntilRelease > 60">
            {{ $t('calllist.states.accepted.by_myself') }}
          </template>
          <template v-else-if="secondsUntilRelease > 0">
            {{ $t('calllist.states.accepted.released_in', { countdown: releaseCountdown }) }},
            {{ $t('calllist.states.accepted.by_myself') }}
          </template>
          <template v-else>
            {{ $t('calllist.states.accepted.releasing') }}
          </template>
        </template>
        <template v-else>
          {{ call.accepted_by_username }}
        </template>
      </div>
    </div>

    <!-- Unhandled Call -->
    <div v-else class="flex items-center" :class="[markEscalated ? 'text-yellow-700' : '']">
      <div class="mr-1">
        <MdiIcon
          width="14"
          height="14"
          :icon="mdiClockAlertOutline"
        />
      </div>
      <div class="truncate">
        <template v-if="markEscalated">
          {{ $t('calllist.states.unhandled.escalated') }},
        </template>
        <template v-if="call.notified_users">
          <template v-if="isUrgent">
            {{ $t('calllist.states.unhandled.people_informed', { count: call.notified_users.length }) }}
          </template>
          <template v-else-if="call.pending_users?.length">
            {{ $t('calllist.states.unhandled.answers_open', { count: call.pending_users.length, notified: call.notified_users.length }) }}
          </template>
          <template v-else>
            {{ $t('calllist.states.unhandled.waiting_for_decision') }}
          </template>
        </template>
        <template v-else-if="isRejectOnlyCallHandlingMode">
          {{ $t('calllist.states.unhandled.waiting_for_edit') }}
        </template>
        <template v-else>
          {{ $t('calllist.states.unhandled.waiting_for_decision') }}
        </template>
      </div>
    </div>
  </div>
</template>
