<script setup lang="ts">
  import { useRoute, useRouter } from 'vue-router'
  import SwipeToAction from '@/components/ui/SwipeToAction.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue'
  import { mdiLifebuoy } from '@mdi/js'
  import { EmergencyNotification, State } from '@/capacitor'
  import { useI18n } from 'vue-i18n'
  import { logError } from '@/common/utils'
  import { getCall } from '@/api/requests'
  import { Call } from '@/types'

  const router = useRouter()
  const route = useRoute()
  const i18n = useI18n()

  const ageSeconds = ref(0)
  const username = computed(() => route.params.username ?? i18n.t('common.someone'))
  const relatedCallId = computed(() => route.params.call_id ?? '')

  const call = ref<Call|null>(null)
  const isTesting = ref<boolean>(false)

  watchEffect(async () => {
    if (relatedCallId.value) {
      try {
        call.value = (await getCall(Number(relatedCallId.value)))?.call ?? null
      } catch (e) {
        logError('failed to fetch related call', e)
      }
    }
  })

  let ageInterval: number | undefined
  let autoCloseTimeout: number | undefined

  onMounted(async () => {
    window.clearInterval(ageInterval)
    window.clearTimeout(autoCloseTimeout)

    ageInterval = window.setInterval(() => ageSeconds.value++, 1000)
    autoCloseTimeout = window.setTimeout(() => close(), 60_000)

    const { isRunningTests } = await State.isRunningTests()
    isTesting.value = isRunningTests
  })

  onBeforeUnmount(() => {
    window.clearInterval(ageInterval)
    window.clearTimeout(autoCloseTimeout)
  })

  async function close () {
    await router.push({ name: 'callList' })

    await EmergencyNotification.closeActivity()
  }
</script>

<template>
  <div class="bg-gray-100 overflow-y-auto h-screen flex flex-col">
    <div class="border-[20px] border-animation flex-1 flex items-center justify-center">
      <div class="p-5 flex flex-col space-y-4 text-center">
        <div class="mx-auto text-csBlue-500">
          <MdiIcon
            :icon="mdiLifebuoy"
            :size="64"
          />
        </div>
        <div class="space-y-2 text-3xl">
          <h1 class="text-4xl font-bold uppercase tracking-wider">
            SOS
          </h1>

          <div class="font-semibold leading-tight">
            {{ $t('sos.needs_help', [username]) }}
          </div>

          <template v-if="call && call.text_short">
            <div v-if="call.is_presence">
              {{ $t('people.states.present') }}
              {{ $t('people.present_at', { target: call.text_short }) }}
            </div>
            <div v-else>
              {{ $t('people.states.busy') }}
              {{ $t('people.on_the_way_to', { target: call.text_short }) }}
            </div>
          </template>

          <div class="text-gray-700">
            {{ $t('sos.seconds_ago', { count: ageSeconds }) }}
          </div>

          <SwipeToAction id="close-sos-action" class="!mt-16" @click="isTesting ? close() : null" @swiped="close()">
            {{ $t('common.actions.swipe_to_dismiss') }}
          </SwipeToAction>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .border-animation {
    animation: border-alarm 1s infinite;
  }

  @keyframes border-alarm {
    0%, 100% {
      border-color: theme('colors.pink.300');
    }

    50% {
      border-color: theme('colors.pink.500');
    }
  }
</style>
