import { State } from '@/store/index'
import { Action, Store } from 'vuex'
import { Features, License } from '@/types'
import { ifNative, Permissions as PermissionsPlugin, State as StatePlugin } from '@/capacitor'
import { logDebug, logInfo, logWarning } from '@/common/utils'
import { storageGet, storageSet } from '@/bootstrap/storage'
import { PromptPermissionsMode } from '@/store/ui.state'

export interface AppState {
  license: License
  features: Features
  permissions: Permissions
}

export interface Permissions extends Record<string, boolean> {
  'android.permission.READ_PHONE_STATE': boolean
  'android.permission.USE_FULL_SCREEN_INTENT': boolean
  'android.permission.ACCESS_NOTIFICATION_POLICY': false,
  'android.permission.SCHEDULE_EXACT_ALARM': false,
  'android.permission.FOREGROUND_SERVICE': false,
  'android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS': false,
  'android.permission.POST_NOTIFICATIONS': false,
}

const sharedStorageLicenseStateKey = 'ch.caresuite.app.license'

// restoreLicenseState restores the license state from localStorage.
export async function restoreLicenseState (store: Store<State>) {
  const storedState = await storageGet(sharedStorageLicenseStateKey)
  if (storedState) {
    const data = JSON.parse(storedState) as License
    if (data) {
      store.commit('app/setLicense', data)
    }
  }
}

export async function updateStoredLicenseState (state: License) {
  await storageSet(sharedStorageLicenseStateKey, JSON.stringify(state))
  ifNative(async () => {
    await StatePlugin.refresh()
  })
}

const state: AppState = {
  license: {
    id: 0,
    name: '',
    features: { app: false },
    limits: { app_clients: 0 },
  },
  features: {
    presences: true,
    display_names: false,
    team_view: false,
    subscriptions: false,
    subscriptions_user_defined: false,
    subscriptions_confirmation_required: false,
    logout_disabled: false,
    phone_call_support: false,
    version: 0,
  },
  permissions: {
    'android.permission.READ_PHONE_STATE': false,
    'android.permission.POST_NOTIFICATIONS': false,
    'android.permission.USE_FULL_SCREEN_INTENT': false,
    'android.permission.ACCESS_NOTIFICATION_POLICY': false,
    'android.permission.SCHEDULE_EXACT_ALARM': false,
    'android.permission.FOREGROUND_SERVICE': false,
    'android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS': false,
  },
}

const getters = {}

const mutations = {
  setFeatures (state: AppState, features: Features | undefined) {
    if (features !== undefined && typeof features === 'object') {
      state.features = features
    }
  },
  setPermissions (state: AppState, permissions: Permissions) {
    state.permissions = permissions
  },
  setLicense (state: AppState, license: License) {
    state.license = license
    updateStoredLicenseState(license)
  },
  clearPermissionsPromptCache (state: AppState) {
    for (const key in state.permissions) {
      if (state.permissions.hasOwnProperty(key)) {
        sessionStorage.removeItem(`permission_asked.${key}`)
        state.permissions[key] = false
      }
    }
  },
}

const actions: Record<string, Action<AppState, State>> = {
  /**
   * Ask for a native Android permission.
   * Calls to this action only trigger once per browser session.
   */
  async askForPermission ({ dispatch, state, commit, rootState }, payload: { permission: string, message: string, min_sdk_version: number, action: string, actionFn: () => Promise<void> }) {
    if (state.permissions.hasOwnProperty(payload.permission) && state.permissions[payload.permission]) {
      logDebug('permission already granted (cached)', payload.permission)
      return
    }

    const result = await PermissionsPlugin.granted({ permission: payload.permission, min_sdk_version: payload.min_sdk_version })
    if (result && result.granted) {
      logDebug('permission is already granted', payload.permission)
      commit('setPermissions', { ...state.permissions, [payload.permission]: true })
      return
    }

    // Make sure to only prompt for this permission once every browser session.
    const cacheKey = `permission_asked.${payload.permission}`
    if (rootState.ui.promptPermissions < PromptPermissionsMode.ForcePrompt && sessionStorage.getItem(cacheKey)) {
      logInfo('skip recurring ask for permission', payload.permission)
      return
    }

    sessionStorage.setItem(cacheKey, 'true')

    let fn = async () => {
      const response = await PermissionsPlugin.prompt({ permission: payload.permission })
      if (!response) {
        logWarning('permission prompt failed', payload.permission)
        return
      }
      if (response.granted) {
        logInfo('permission granted', payload.permission)
        commit('setPermissions', { ...state.permissions, [payload.permission]: true })
      }
    }

    if (payload.actionFn) {
      fn = payload.actionFn
    }

    await dispatch('ui/showSnackbar', {
      message: payload.message,
      action: payload.action,
      dismissable: rootState.ui.promptPermissions !== PromptPermissionsMode.ForcePrompt,
      actionFn: fn,
    }, { root: true })
  },

  async getGrantedPermissions ({ commit, state }) {
    for (const key in state.permissions) {
      if (state.permissions.hasOwnProperty(key) && !state.permissions[key]) {
        const result = await PermissionsPlugin.granted({ permission: key })
        if (result?.granted) {
          commit('setPermissions', { ...state.permissions, [key]: true })
        }
      }
    }
    return state.permissions
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
