<script setup lang="ts">
  import { mdiAccountRemove, mdiAccountSwitch } from '@mdi/js'
  import TextButton from '@/components/material/TextButton.vue'
  import Modal from '@/components/modal/Modal.vue'
  import { computed, ref, watch, watchEffect } from 'vue'
  import FormInput from '@/components/material/FormInput.vue'
  import { getDisplayNames, registerDisplayName, removeDisplayName } from '@/api/requests'
  import { useStore } from '@/store'
  import { logError } from '@/common/utils'
  import ConfirmModal from '@/components/modal/ConfirmModal.vue'
  import { useRoute, useRouter } from 'vue-router'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import DrawerListItem from '@/components/material/DrawerListItem.vue'
  import { useI18n } from "vue-i18n"

  const i18n = useI18n()
  const store = useStore()
  const route = useRoute()
  const router = useRouter()

  const modalVisible = ref(false)
  const mustSetName = ref(false)
  const currentDisplayName = ref(store.state.user.user.current_display_name)
  const error = ref('')

  const recentDisplayNames = ref<string[]>([])
  const confirmRemoveDisplayName = ref('')
  const submitLoading = ref(false)

  const activeDisplayName = computed(() => store.state.user.user.current_display_name)

  const emit = defineEmits(['click'])

  async function submit () {
    submitLoading.value = true
    error.value = ''

    try {
      await registerDisplayName({ display_name: currentDisplayName.value })
    } catch (e: any) {
      error.value = i18n.t('common.states.save_failed')
      logError(e)
      return
    } finally {
      submitLoading.value = false
    }

    modalVisible.value = false

    await store.dispatch('user/setCurrentDisplayName', currentDisplayName.value)

    // Enforce subscriptions check after name change.
    if (store.state.app.features.subscriptions_confirmation_required) {
      await router.push({ name: 'subscription' })

      await store.dispatch('ui/showSnackbar', {
        message: i18n.t('subscription.snackbars.subscription_required'),
        timeout: 5000,
      })
    }
  }

  async function remove () {
    submitLoading.value = true
    error.value = ''

    const name = confirmRemoveDisplayName.value

    try {
      await removeDisplayName({ display_name: name })

      recentDisplayNames.value = recentDisplayNames.value.filter(recentName => {
        return recentName.toLowerCase() !== name.toLowerCase()
      })
    } catch (e: any) {
      error.value = i18n.t('common.states.remove_failed')
      logError(e)
      submitLoading.value = false
      return
    } finally {
      confirmRemoveDisplayName.value = ''
      submitLoading.value = false
    }
  }

  watch(modalVisible, async (value) => {
    if (value) {
      currentDisplayName.value = ''
    }

    if (value && store.state.user.user.id > 0) {
      const response = await getDisplayNames()
      if (!response || !response.display_names) {
        return
      }
      recentDisplayNames.value = response.display_names.map(d => d.display_name).filter(name => {
        return name.toLowerCase() !== currentDisplayName.value.toLowerCase()
      })
    }
  })

  // Enforce a display name if the feature gets enabled.
  watchEffect(() => {
    const hasUser = store.state.user.user.id > 0
    if (!hasUser) {
      return
    }

    const mustSetDisplayName = store.state.app.features.display_names && !store.state.user.user.current_display_name
    const isIgnoredRoute = ['login', 'logout', 'register', 'emergency'].includes(route.name as string)

    if (mustSetDisplayName && !isIgnoredRoute) {
      modalVisible.value = true
      mustSetName.value = true
    } else {
      mustSetName.value = false
    }
  })

  function onClick () {
    modalVisible.value = true
    emit('click')
  }
</script>

<template>
  <DrawerListItem :icon="mdiAccountSwitch" class="py-8" @click="onClick">
    <div class="min-w-0 text-sm flex-1 leading-5">
      <div class="font-semibold text-gray-800 text-2xs">
        {{ $t('displayname.title') }}
      </div>
      <div class="truncate w-full">
        <template v-if="activeDisplayName">
          {{ activeDisplayName }}
        </template>
        <div v-else>
          <div class="italic">
            {{ $t('displayname.unknown') }}
          </div>
        </div>
      </div>
    </div>
  </DrawerListItem>

  <Modal :title="$t('displayname.modal.title')" :visible="modalVisible">
    <p class="mb-4 text-sm">
      <template v-if="recentDisplayNames.length > 0">
        {{ $t('displayname.modal.text.1') }}
      </template>
      <template v-else>
        {{ $t('displayname.modal.text.0') }}
      </template>
    </p>

    <FormInput
      id="current_display_name"
      v-model="currentDisplayName"
      :label="$t('displayname.title')"
      :disabled="submitLoading"
      :errors="{ 'current_display_name': error }"
      inputClass="pr-24"
    >
      <template #input>
        <button
          :disabled="currentDisplayName === ''"
          :class="[currentDisplayName === '' ? '!text-gray-400 cursor-not-allowed' : 'text-csBlue-500']"
          class="absolute -right-2 -bottom-1 px-2 text-button rounded flex justify-center items-center uppercase text-base leading-none whitespace-nowrap h-10 border border-transparent font-semibold"
          @click="submit"
        >
          {{ $t('common.actions.save') }}
        </button>
      </template>
    </FormInput>

    <div v-if="recentDisplayNames.length > 0" class="mt-8 relative">
      <p class="font-bold text-2xs mb-2">
        {{ $t('displayname.modal.recent') }}
      </p>
      <ul class="overflow-y-auto -ml-2" :class="{'pb-12 max-h-[130px] ': recentDisplayNames.length > 4}">
        <li v-for="displayName in recentDisplayNames" :key="displayName" class="flex items-center gap-2">
          <a v-wave href="#" class="flex-1 p-2 rounded" @click.prevent="currentDisplayName = displayName">
            {{ displayName }}
          </a>
          <a href="#" @click.prevent="confirmRemoveDisplayName = displayName">
            <MdiIcon :icon="mdiAccountRemove" class="w-4" />
          </a>
        </li>
      </ul>
      <div
        v-if="recentDisplayNames.length > 4"
        class="pointer-events-none bg-gradient-to-t from-white z-20 absolute -bottom-1 h-16 w-full"
      />
    </div>

    <template #actions>
      <TextButton v-if="!mustSetName" class="text-csBlue-800" @click="modalVisible = false">
        {{ $t('common.actions.cancel') }}
      </TextButton>
    </template>
  </Modal>

  <ConfirmModal
    :actionLabel="$t('common.actions.remove')"
    :title="$t('common.actions.confirm')"
    :text="$t('displayname.modal.remove', { name: confirmRemoveDisplayName })"
    :visible="confirmRemoveDisplayName !== ''"
    @confirm="remove"
    @close="confirmRemoveDisplayName = ''"
  />
</template>