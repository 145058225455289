<script setup lang="ts">
  import { useDrag, useSpring } from 'vue-use-gesture'
  import { computed, ref, watch } from 'vue'
  import { mdiArrowRight } from '@mdi/js'
  import MdiIcon from '@/components/ui/MdiIcon.vue'

  const emit = defineEmits(['swiped'])
  const props = defineProps({
    disabled: {
      type: Boolean,
      default: false,
    }
  })

  // @ts-ignore // this is a bug in type definition of library.
  const [{ x }, set] = useSpring(() => ({ x: 0 }))

  const container = ref<HTMLElement | null>(null)
  const swipedAway = ref(false)
  const isDragging = ref(false)

  const containerWidth = computed(() => container.value?.offsetWidth ?? 0)

  let resetTimeout = -1

  watch(swipedAway, (newSwipedAway) => {
    if (newSwipedAway) {
      clearTimeout(resetTimeout)
      emit('swiped')
      resetTimeout = setTimeout(() => {
        reset(true)
      }, 2000)
    }
  })

  // @ts-ignore
  const bind = useDrag(({ active, movement: [mx] }) => {
    // Do nothing when swiping left or action is done or disabled.
    if (mx < 0 || swipedAway.value || props.disabled) {
      return
    }

    if (swipedPercentage.value > 50) {
      swipedAway.value = true
      set({ x: containerWidth.value, immediate: true })
      return
    }

    set({ x: active ? mx : 0, immediate: active })
  }, { axis: 'x' })

  const style = computed(() => ({ transform: `translate3d(${x.value}px,0,0)` }))
  const onPointerDownHandler = computed(() => bind().onPointerDown)

  function onPointerDown (e: MouseEvent) {
    onPointerDownHandler.value(e)
    isDragging.value = true
  }

  function onPointerUp () {
    isDragging.value = false
    reset()
  }

  const swipedPercentage = computed(() => {
    if (!container.value) {
      return 0
    }
    return Math.min(100 / containerWidth.value * x.value, 100)
  })

  function reset (force = false) {
    if (force || !swipedAway.value) {
      set({ x: 0, immediate: true })
      swipedAway.value = false
    }
  }
</script>

<template>
  <div
    ref="container"
    class="rounded-full h-20 bg-gray-200 shadow-inner relative overflow-hidden p-2"
    :class="{ 'pointer-events-none opacity-80': disabled }"
  >
    <div
      class="dragger absolute select-none shadow z-10 bg-csBlue-500 w-16 h-16 rounded-full ml-.5 flex items-center justify-center text-white"
      :class="{
        'bg-csBlue-400': isDragging ,
        'bg-gray-400': disabled,
      }"
      :style="style"
      @pointerdown="onPointerDown"
      @pointerup="onPointerUp"
    >
      <MdiIcon type="mdi" :size="48" :icon="mdiArrowRight" />
    </div>
    <div
      class="z-0 text-xs leading-tight text-left h-full ml-16 flex items-center p-4"
      :style="{
        'opacity': (100 - (swipedPercentage * 5)) / 100,
      }"
    >
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .dragger {
    cursor: grab;
    touch-action: pan-y;
  }
</style>