<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { useStore } from '@/store'
  import { useRoute, useRouter } from 'vue-router'
  import { Alert } from '@/types'
  import { formatDistanceStrict, parseISO, useTime } from '@/common/time'
  import SwipeToAction from '@/components/ui/SwipeToAction.vue'
  import { EmergencyNotification } from '@/capacitor'
  import { mdiAlertDecagramOutline } from '@mdi/js'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { logError } from '@/common/utils'

  const loading = ref(true)
  const store = useStore()

  const route = useRoute()
  const router = useRouter()

  onMounted(fetch)

  async function fetch () {
    loading.value = true
    try {
      await store.dispatch('alert/fetch')
    } catch (e) {
      logError('failed to fetch alerts', e)
    } finally {
      loading.value = false
    }
  }

  const alert = computed<Alert | undefined>(() => store.getters['alert/byId'](Number(route.params.id)))

  const alertOpenedAt = computed(() => parseISO(alert.value?.created_at ?? new Date().toDateString()))
  const alertAge = computed(() => {
    return formatDistanceStrict(alertOpenedAt.value, useTime().value.now, {
      addSuffix: true,
    })
  })

  // Close the activity when the alert is closed.
  watch(alert, (newAlert) => {
    if (newAlert?.closed_at !== null) {
      close()
    }
  })

  async function close () {
    loading.value = true

    try {
      store.dispatch('alert/confirmAlert', alert.value)
    } finally {
      await router.push({ name: 'callList' })

      await EmergencyNotification.closeActivity()
    }
  }
</script>

<template>
  <div class="bg-gray-100 overflow-y-auto h-screen flex flex-col">
    <div v-if="!loading" class="border-[20px] border-animation flex-1 flex items-center justify-center">
      <div class="p-5 flex flex-col space-y-4 text-center">
        <div class="mx-auto text-csBlue-500">
          <MdiIcon
            :icon="mdiAlertDecagramOutline"
            :size="64"
          />
        </div>
        <div v-if="alert" class="space-y-2 text-3xl">
          <h1 class="text-4xl font-bold leading-tight uppercase tracking-wider">
            {{ alert.title }}
          </h1>

          <div v-if="alert.body" class="font-semibold leading-tight">
            {{ alert.body }}
          </div>

          <div class="text-gray-700">
            {{ alertAge }}
          </div>

          <SwipeToAction class="!mt-16" @swiped="close()">
            {{ $t('common.actions.swipe_to_dismiss') }}
          </SwipeToAction>
        </div>
        <div v-else class="space-y-8 flex flex-col items-center">
          <div class="space-y-4">
            <p class="text-2xl">
              <strong>{{ $t('alert.new_alert') }}</strong>
            </p>
            <p>
              {{ $t('alert.errors.unable_to_load') }}
            </p>
            <p>
              {{ $t('alert.errors.proof_network') }}
            </p>
          </div>
          <div class="!mt-12 flex flex-col justify-center space-y-2">
            <ContainedButton class="bg-csBlue-500 w-[200px]" @click.prevent="fetch()">
              {{ $t('common.actions.retry') }}
            </ContainedButton>
            <TextButton @click.prevent="close()">
              {{ $t('common.actions.close') }}
            </TextButton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-screen">
      <div class="flex flex-col items-center">
        <CircularProgress />
        <div class="mt-6 text-2xs text-gray-500">
          {{ $t('common.loading') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .border-animation {
    animation: border-alarm 1s infinite;
  }

  @keyframes border-alarm {
    0%, 100% {
      border-color: theme('colors.blue.300');
    }

    50% {
      border-color: theme('colors.blue.500');
    }
  }
</style>