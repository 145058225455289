import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/routes'
import { State } from '@/store'
import { Store } from 'vuex'
import { EmergencyNotification } from '@/capacitor'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [],
})

export async function setupRouter (store: Store<State>) {
  routes(store).forEach(r => router.addRoute(r))

  router.afterEach((to, from, failure) => {
    if (!failure) {
      // Track the current route in the Vuex store.
      store.commit('ui/setCurrentRoute', {
        name: to.name,
        params: to.params,
        meta: to.meta,
      })
    }
  })
  router.beforeEach((to, from, next) => {
    // Force registration screen for unregistered devices.
    if (to.name !== 'register' && !store.state.client.client.id) {
      next({ name: 'register' })
      return
    }
    // Add the `with-footer` class to the body if the route contains a bottom bar.
    // This ensures bottom elements like the Snackbar are properly positioned.
    document.body.classList.toggle('with-footer', to.meta.footer === true)
    next()
  })

  await EmergencyNotification.setRouter(router)
}

export default router
