<script setup lang="ts">
  import { computed, onMounted, ref } from 'vue'
  import { getRooms, saveSubscription } from '@/api/requests'
  import { logError } from '@/common/utils'
  import { Section, SystemTreeNode } from '@/types'
  import Callout from '@/components/layout/Callout.vue'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import { mdiAlert, mdiDelete } from '@mdi/js'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import ButtonCheckbox from '@/components/ui/ButtonCheckbox.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import ConfirmModal from '@/components/modal/ConfirmModal.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { useStore } from '@/store'
  import { useRouter } from 'vue-router'
  import { useI18n } from "vue-i18n"

  const i18n = useI18n()
  const store = useStore()
  const router = useRouter()

  const loading = ref(true)
  const submitting = ref(false)
  const error = ref(false)
  const confirmVisible = ref(false)
  const rooms = ref<SystemTreeNode[]>([])
  const sections = ref<Section[]>([])

  onMounted(fetch)

  async function fetch () {
    loading.value = true
    error.value = false
    try {
      const response = await getRooms()
      rooms.value = response.rooms
      sections.value = response.sections
      subscription.value = response.subscription
    } catch (e) {
      logError('failed to fetch rooms', e)
      error.value = true
    } finally {
      loading.value = false
    }
  }

  async function submit () {
    submitting.value  = true
    try {
      await saveSubscription(subscription.value)

      store.commit('user/setNodesPriority', subscription.value)

      const clearSnackbar = await store.dispatch('ui/showSnackbar', {
        message: i18n.t('subscription.snackbars.subscription_saved')
      })
      window.setTimeout(clearSnackbar, 4000)

      await router.push({ name: 'callList' })
    } catch (e) {
      logError('failed to save subscription', e)
      const clearSnackbar = await store.dispatch('ui/showSnackbar', {
        message: 'Fehler beim Speichern, bitte versuche es erneut!'
      })
      window.setTimeout(clearSnackbar, 4000)
    } finally {
      submitting.value = false
    }
  }

  const hasRooms = computed(() => Object.values(roomsBySection.value).length > 0)
  const sortedSections = computed(() => [...sections.value].sort((a, b) => a.sort_order - b.sort_order))

  const roomsBySection = computed(() => {
    const result: Record<string, SystemTreeNode[]> = {}

    for (const section of sortedSections.value) {
      const roomsOfSection = rooms.value.filter(room => room.section_id === section.id)

      if (roomsOfSection.length === 0) {
        continue
      }

      result[section.name] = [...roomsOfSection].sort((a, b) => a.sort_order - b.sort_order)
    }

    return result
  })

  const subscription = ref<number[]>([])

  function updateSubscription (value: boolean, roomId: number) {
    if (value) {
      subscription.value = [...new Set(subscription.value).add(roomId).values()]
    } else {
      subscription.value = subscription.value.filter(id => id !== roomId)
    }
  }
</script>

<template>
  <div
    class="flex flex-1 flex-col h-screen-header"
    :class="{'justify-center': error || loading }"
  >
    <CircularProgress v-if="loading" class="mx-auto" />
    <template v-else-if="error">
      <Callout :icon="mdiAlert" :title="$t('common.states.error')">
        <p>
          {{ $t('subscription.callouts.unable_to_load_rooms') }}
        </p>
        <template #actions>
          <ContainedButton class="bg-csBlue-500 !mt-8 w-[200px]" @click.prevent="fetch()">
            {{ $t('common.actions.retry') }}
          </ContainedButton>
        </template>
      </Callout>
    </template>
    <template v-else>
      <div class="flex flex-col flex-1 container mx-auto overflow-y-auto pb-8">
        <div v-if="!hasRooms" class="flex-1 flex flex-col justify-center">
          <Callout title="Keine Räume vorhanden">
            <div class="text-gray-600">
              {{ $t('subscription.callouts.no_available_rooms') }}
            </div>
            <TextButton class="min-w-[200px] bg-gray-300" @click="fetch">
              {{ $t('common.actions.reload') }}
            </TextButton>
          </Callout>
        </div>
        <template v-else>
          <div class="my-6">
            <h1 class="text-lg font-semibold text-csBlue-500">
              {{ $t('subscription.title') }}
            </h1>
            <p class="text-sm text-gray-600 hyphens-auto">
              {{ $t('subscription.description') }}
            </p>
          </div>
          <div class="space-y-8">
            <div v-for="(nodes, section) in roomsBySection" :key="section">
              <h2 class="bg-gray-50 py-2 sticky top-0 z-10 text-base font-semibold text-csBlue-500 flex">
                {{ section }}
              </h2>
              <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                <ButtonCheckbox
                  v-for="room in nodes"
                  :key="room.id"
                  :modelValue="subscription.includes(room.id)"
                  class="min-h-[50px]"
                  @update:modelValue="updateSubscription($event, room.id)"
                >
                  <div class="leading-tight truncate">
                    <span v-if="room.text_short" class="block text-2xs font-semibold">
                      {{ room.text_short }}
                    </span>
                    <template v-if="room.text_short !== room.text_long">
                      {{ room.text_long }}
                    </template>
                  </div>
                </ButtonCheckbox>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="container border-t border-gray-200 mx-auto py-2">
        <div class="flex gap-2">
          <ContainedButton class="bg-gray-700" @click="confirmVisible = true">
            <MdiIcon
              :icon="mdiDelete"
              class="w-5 h-5"
            />
          </ContainedButton>

          <ContainedButton
            class="flex flex-1 bg-csBlue-500 w-full"
            :loading="submitting"
            @click.prevent="submit"
          >
            <div>
              {{ $t('subscription.actions.subscribe', {count: subscription.length}) }}
            </div>
          </ContainedButton>
        </div>
      </div>
    </template>
    <ConfirmModal
      :visible="confirmVisible"
      :title="$t('subscription.reset_prompt.title')"
      :text="$t('subscription.reset_prompt.description')"
      :actionLabel="$t('common.actions.reset')"
      @confirm="subscription = []"
      @close="confirmVisible = false"
    />
  </div>
</template>

<style>
</style>