<script setup lang="ts">
  defineProps({
    title: {
      type: String,
      required: true,
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      required: true,
    }
  })

  defineOptions({
    inheritAttrs: false,
  })

  const emit = defineEmits(['close'])

  function backdropClick (e: MouseEvent) {
    if (e.target && (e.target as HTMLElement).classList.contains('modal-backdrop')) {
      emit('close')
    }
  }
</script>

<template>
  <teleport to="body">
    <transition
      enterFromClass="opacity-0"
      enterActiveClass="transform transform-opacity duration-300"
      enterToClass="opacity-1"
      leaveFromClass="opacity-1"
      leaveActiveClass="transform transform-opacity duration-300 delay-100"
      leaveToClass="opacity-0"
    >
      <div v-if="visible" v-bind="$attrs" class="modal-backdrop fixed inset-0 z-50 bg-black/40" @click="backdropClick">
        <div class="absolute top-1/2 transform -translate-y-1/2 w-full px-2">
          <div class="max-w-full w-80 mx-auto bg-white elevation-12 rounded">
            <h1 class="text-xl h-16 flex items-center px-6 font-semibold leading-none" :class="{'justify-center': centerTitle}">
              {{ title }}
            </h1>
            <div class="px-6 text-gray-600 leading-snug text-base">
              <slot />
            </div>
            <div class="mt-6 flex justify-end px-4 pb-2">
              <slot name="actions" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>