<script setup lang="ts">
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { mdiCheckCircle } from '@mdi/js'

  const checked = defineModel<boolean>()
</script>

<template>
  <div class="btn-checkbox relative select-none">
    <span class="absolute -right-1.5 -top-1.5 rounded text-csBlue-500 bg-white rounded-full z-10">
      <MdiIcon
        v-if="checked"
        class="w-4 h-4"
        :icon="mdiCheckCircle"
      />
    </span>
    <button
      class="relative text-left border p-2 text-xs rounded w-full truncate h-full flex items-start"
      :class="{
        'border-csBlue-500 text-csBlue-500': checked,
        'bg-gray-100 border-gray-300 text-gray-600 hover:text-csBlue-400': !checked,
      }"
      @click.prevent="checked = !checked"
    >
      <slot />
    </button>
  </div>
</template>

