<template>
  <div
    class="w-12 mdc-circular-progress mdc-circular-progress--large mdc-circular-progress--indeterminate aspect-[1]"
    role="progressbar"
  >
    <div class="mdc-circular-progress__determinate-container">
      <svg class="mdc-circular-progress__determinate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <circle
          class="mdc-circular-progress__determinate-track"
          cx="24"
          cy="24"
          r="18"
          stroke-width="4"
        />
        <circle
          class="mdc-circular-progress__determinate-circle"
          cx="24"
          cy="24"
          r="18"
          stroke-dasharray="113.097"
          stroke-dashoffset="NaN"
          stroke-width="4"
        />
      </svg>
    </div>
    <div class="mdc-circular-progress__indeterminate-container">
      <div class="mdc-circular-progress__spinner-layer">
        <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-left">
          <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="24"
              cy="24"
              r="18"
              stroke-dasharray="113.097"
              stroke-dashoffset="56.549"
              stroke-width="4"
            />
          </svg><!--must be no space between divs-->
        </div>
        <div class="mdc-circular-progress__gap-patch">
          <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="24"
              cy="24"
              r="18"
              stroke-dasharray="113.097"
              stroke-dashoffset="56.549"
              stroke-width="4"
            />
          </svg>
        </div>
        <div class="mdc-circular-progress__circle-clipper mdc-circular-progress__circle-right">
          <svg class="mdc-circular-progress__indeterminate-circle-graphic" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <circle
              cx="24"
              cy="24"
              r="18"
              stroke-dasharray="113.097"
              stroke-dashoffset="56.549"
              stroke-width="4"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.mdc-circular-progress {
  display: inline-flex;
  position: relative;
  direction: ltr;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__determinate-container {
  opacity: 0;
}

.mdc-circular-progress__determinate-container {
  transform: rotate(-90deg);
}

.mdc-circular-progress__determinate-container, .mdc-circular-progress__indeterminate-circle-graphic, .mdc-circular-progress__indeterminate-container, .mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-circle-graphic, .mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-track {
  stroke: transparent;
}

.mdc-circular-progress__determinate-circle {
  transition: stroke-dashoffset 500ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-circular-progress__determinate-circle, .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--mdc-theme-primary, #203860);
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__indeterminate-container {
  opacity: 1;
  animation: mdc-circular-progress-container-rotate 1568.2352941176ms linear infinite;
}

.mdc-circular-progress__indeterminate-container {
  font-size: 0;
  letter-spacing: 0;
  white-space: nowrap;
  opacity: 0;
}

.mdc-circular-progress__determinate-container, .mdc-circular-progress__indeterminate-circle-graphic, .mdc-circular-progress__indeterminate-container, .mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__spinner-layer {
  animation: mdc-circular-progress-spinner-layer-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress__determinate-container, .mdc-circular-progress__indeterminate-circle-graphic, .mdc-circular-progress__indeterminate-container, .mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__circle-clipper {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-left .mdc-circular-progress__indeterminate-circle-graphic {
  animation: mdc-circular-progress-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic {
  width: 200%;
}

.mdc-circular-progress__determinate-circle-graphic, .mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-container, .mdc-circular-progress__indeterminate-circle-graphic, .mdc-circular-progress__indeterminate-container, .mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-circle, .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--mdc-theme-primary, #203860);
}

.mdc-circular-progress__gap-patch {
  position: absolute;
  top: 0;
  left: 47.5%;
  box-sizing: border-box;
  width: 5%;
  height: 100%;
  overflow: hidden;
}

.mdc-circular-progress__gap-patch .mdc-circular-progress__indeterminate-circle-graphic {
  left: -900%;
  width: 2000%;
  transform: rotate(180deg);
}

.mdc-circular-progress__determinate-circle-graphic, .mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-container, .mdc-circular-progress__indeterminate-circle-graphic, .mdc-circular-progress__indeterminate-container, .mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-circle, .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--mdc-theme-primary, #203860);
}

.mdc-circular-progress__circle-clipper {
  display: inline-flex;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
}

.mdc-circular-progress--indeterminate .mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  animation: mdc-circular-progress-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.mdc-circular-progress__circle-right .mdc-circular-progress__indeterminate-circle-graphic {
  left: -100%;
}

.mdc-circular-progress__circle-clipper .mdc-circular-progress__indeterminate-circle-graphic {
  width: 200%;
}

.mdc-circular-progress__determinate-circle-graphic, .mdc-circular-progress__indeterminate-circle-graphic {
  fill: transparent;
}

.mdc-circular-progress__determinate-container, .mdc-circular-progress__indeterminate-circle-graphic, .mdc-circular-progress__indeterminate-container, .mdc-circular-progress__spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mdc-circular-progress__determinate-circle, .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--mdc-theme-primary, #203860);
}

@keyframes mdc-circular-progress-right-spin {
  0% {
    transform: rotate(-265deg);
  }
  50% {
    transform: rotate(-130deg)
  }
  100% {
    transform: rotate(-265deg)
  }
}

@keyframes mdc-circular-progress-left-spin {
  0% {
    transform: rotate(265deg);
  }
  50% {
    transform: rotate(130deg);
  }
  100% {
    transform: rotate(265deg);
  }
}

@keyframes mdc-circular-progress-spinner-layer-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  100% {
    transform: rotate(1080deg);
  }
}

@keyframes mdc-circular-progress-container-rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
