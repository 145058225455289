<template>
  <div class="flex-1">
    <section class="mb-8">
      <div class="my-1">
        <slot />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DrawerList',
  })
</script>
