<script setup lang="ts">
  import { computed, PropType, ref } from 'vue'
  import { Alert } from '@/types'
  import { formatTime, parseISO } from '@/common/time'

  import { mdiAlert, mdiCheckBold, mdiCheckCircleOutline, mdiInformation } from '@mdi/js'

  import { logError } from '@/common/utils'
  import { useStore } from '@/store'
  import SwipeableItem from '@/components/ui/SwipeableItem.vue'
  import ActionableListItem from '@/components/ui/ActionableListItem.vue'
  import { useI18n } from "vue-i18n"

  const i18n = useI18n()
  const store = useStore()
  const loading = ref(false)

  const props = defineProps({
    alert: {
      type: Object as PropType<Alert>,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  })

  const isActionable = computed(() => !props.readOnly && !isConfirmed.value && !props.alert.closed_at)
  const isConfirmed = computed(() => store.state.alert.confirmedAlerts.hasOwnProperty(props.alert.id))
  const confirmedAt = computed(() => isConfirmed.value ? store.state.alert.confirmedAlerts[props.alert.id] : null)
  const createdAtTime = computed(() => formatTime(parseISO(props.alert.created_at)))

  const isInfo = computed(() => props.alert.class.toLowerCase() === 'info')
  const isAlert = computed(() => props.alert.class.toLowerCase() === 'alert')
  const isCritical = computed(() => props.alert.class.toLowerCase() === 'critical')
  const isClosed = computed(() => !!props.alert.closed_at)

  const markWarning = computed(() => isAlert.value && !isClosed.value && !isConfirmed.value)
  const markCritical = computed(() => isCritical.value && !isClosed.value && !isConfirmed.value)

  const classStr = computed(() => {
    switch (props.alert.class.toLowerCase()) {
    case 'alert':
      return i18n.t('alert.types.alert')
    case 'critical':
      return i18n.t('alert.types.critical')
    default:
      return i18n.t('alert.types.info')
    }
  })

  const iconConfig = computed(() => {
    switch (true) {
    case isClosed.value || isConfirmed.value:
      return { icon: mdiCheckCircleOutline, color: 'text-gray-500' }
    case isInfo.value:
      return { icon: mdiInformation, color: 'text-csBlue-600' }
    case isAlert.value:
      return { icon: mdiAlert, color: 'text-yellow-500' }
    case isCritical.value:
      return { icon: mdiAlert, color: 'text-red-500' }
    default:
      return { icon: mdiCheckBold, color: 'text-gray-500' }
    }
  })

  async function confirm () {
    loading.value = true

    try {
      await store.dispatch('alert/confirmAlert', props.alert)

      const hide = await store.dispatch('ui/showSnackbar', {
        message: i18n.t('alert.snackbars.read'),
      })
      setTimeout(hide, 2000)
    } catch (e: any) {
      logError(e)

      const hide = await store.dispatch('ui/showSnackbar', {
        message: i18n.t('alert.snackbars.error'),
      })
      setTimeout(hide, 5000)

      throw e
    } finally {
      loading.value = false
    }
  }
</script>

<template>
  <SwipeableItem
    :id="`alert-list-item-${alert.id}`"
    :swipeLabel="$t('common.actions.read')"
    :readOnly="!isActionable"
    @swiped="confirm"
  >
    <ActionableListItem
      :title="alert.title"
      :titleClass="{
        'line-through': isClosed,
        'text-yellow-600': markWarning,
        'text-red-700': markCritical,
      }"
      class="alert"
      :class="{
        'border-yellow-500': markWarning,
        'border-red-500 animate-jiggle': markCritical,
        'is-readonly': readOnly,
      }"
      :icon="iconConfig.icon"
      :iconClass="iconConfig.color"
      :iconLabel="createdAtTime"
      :readOnly="!isActionable"
      :actionLabel="$t('common.actions.read')"
      @action="confirm"
    >
      <template #text>
        <div>
          <template v-if="alert.body">
            {{ alert.body }}<br>
          </template>
          <template v-else>
            {{ classStr }}
          </template>
        </div>
      </template>
      <template #meta>
        <div v-if="isConfirmed || isClosed" class="text-2xs leading-tight text-gray-600">
          <div v-if="confirmedAt">
            {{ $t('alert.states.read_at', { time: formatTime(parseISO(confirmedAt)) }) }}
          </div>
          <div v-if="isClosed && alert.closed_at">
            {{ $t('alert.states.done_at', { time: formatTime(parseISO(alert.closed_at)) }) }}
          </div>
        </div>
      </template>
    </ActionableListItem>
  </SwipeableItem>
</template>