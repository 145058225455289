import { ifNative, State as StatePlugin } from '@/capacitor'
import { storageGet, storageSet } from '@/bootstrap/storage'
import { logDebug } from '@/common/utils'
import { setBackend } from '@/config'
import { State } from '@/store/index'
import { Client } from '@/types'
import { Action, Store } from 'vuex'
import { deleteCurrentClient } from '@/api/requests'

export interface ClientState {
  client: Client
  signalStrength: number
}

const clientData: Client = {
  id: 0,
  name: 'Unregistered',
  unique_id: '',
  last_contact_at: '',
  messaging_token: '',
  current_user_id: 0,
  created_at: '',
  session_id: '',
  phone_number: '',
  is_connected: false,
  app_user_group_id: 0,
}

const state: ClientState = {
  client: clientData,
  signalStrength: -1,
}

// Storage key for the capacitor SharedStorage plugin.
const sharedStorageClientStateKey = 'ch.caresuite.app.client'

// restoreClientState restores the client state from localStorage.
export async function restoreClientState (store: Store<State>) {
  const storedState = await storageGet(sharedStorageClientStateKey)
  if (storedState) {
    const clientData = JSON.parse(storedState) as Client
    if (clientData) {
      await store.dispatch('client/setClient', clientData)
    }
  }
}

export async function updateStoredState (state: Client) {
  await storageSet(sharedStorageClientStateKey, JSON.stringify(state))
  ifNative(async () => {
    await StatePlugin.refresh()
  })
}

const getters = {}

const mutations = {
  setSecret (state: ClientState, payload: string) {
    state.client.secret = payload
  },
  setClient (state: ClientState, payload: Client) {
    state.client = { ...state.client, ...payload }
  },
  logoutClient (state: ClientState) {
    state.client = {
      ...state.client,
      current_user_id: 0,
      app_user_group_id: 0,
      session_id: '',
    }
  },
  clearClient (state: ClientState) {
    state.client = {
      id: 0,
      name: 'Unregistered',
      current_user_id: 0,
      app_user_group_id: 0,
      is_connected: false,
      phone_number: '',
      created_at: '',
      last_contact_at: '',
      messaging_token: '',
      secret: '',
      muted_until: '',
      session_id: '',
    }
  }
}

const actions: Record<string, Action<ClientState, State>> = {
  async setSecret ({ commit, state }, payload: string) {
    commit('setSecret', payload)

    await updateStoredState(state.client)
  },
  async setClient ({ commit, state }, payload: Client) {
    commit('setClient', payload)

    logDebug('Client state updated', JSON.stringify(state.client) as any)
    if (state.client) {
      await updateStoredState(state.client)
    }
  },
  async logoutClient ({ commit, state }) {
    commit('logoutClient')

    await updateStoredState(state.client)
  },
  async unregisterClient ({ dispatch }) {
    try {
      await deleteCurrentClient()
    } catch (e) {
      // Proceed anyway
      console.error('Error unregistering client', e)
    }
    await dispatch('clearClient')
  },
  async clearClient ({ commit, state }) {
    commit('clearClient')

    await setBackend('')
    await updateStoredState(state.client)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
