import { logError, logInfo } from '@/common/utils'
import { ref } from 'vue'
import { PushNotifications, Token } from '@capacitor/push-notifications'
import { checkInClient } from '@/api/requests'
import { State } from '@/store'
import { Store } from 'vuex'
import { Router } from 'vue-router'

const token = ref<string | null>(null)

export function useToken () {
  return token
}

export function setToken (value: string) {
  token.value = value
}

// initFirebaseNative initializes the Firebase SDK for the native platform.
export async function initFirebaseNative (store: Store<State>, router: Router) {
  let permStatus = await PushNotifications.checkPermissions()

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions()
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('push notifications permission not granted')
  }

  // The registration token updated.
  PushNotifications.addListener('registration', (token: Token) => {
    logInfo('native push notification registration succeeded', token)
    setToken(token.value)

    // Send the new token to the database.
    if (token.value && store.state.client.client.id > 0 && store.state.client.client.messaging_token !== token.value) {
      checkInClient({ messaging_token: token.value }, router)
    }
  })

  // There was an error fetching the registration token.
  PushNotifications.addListener('registrationError', (error: any) => {
    logError('failed to get registration token', error)
  })

  // Register with Apple / Google to receive push via APNS/FCM
  await PushNotifications.register()
}

export function initBrowserDummyToken () {
  setToken('no-token')
}