<script setup lang="ts">
  import { useStore } from '@/store'
  import { useRouter } from 'vue-router'
  import { CallListViewMode } from '@/store/ui.state'

  defineProps({
    indicatorClasses: {
      type: String,
      default: '',
    }
  })

  const store = useStore()
  const router = useRouter()

  function onLogoClick () {
    store.commit('ui/setCallListViewMode', CallListViewMode.Priority)
    router.push({ name: 'callList' })
  }
</script>

<template>
  <div class="bg-csBlue-600 h-14 shadow-md z-20 w-full elevation-4 overflow-hidden">
    <div class="mx-4 flex h-full">
      <!-- Left Action Area -->
      <div id="top-action" class="flex-1 flex items-center relative left-[-1.35rem]">
        <slot name="left" />
        <!-- Ping indicator -->
        <div v-if="indicatorClasses" class="pointer-events-none">
          <div class="absolute left-0 ml-9 top-0 mt-4">
            <div class="w-3 h-3 animate-ping rounded-full" :class="indicatorClasses" />
          </div>
          <div class="absolute left-0 ml-9 top-0 mt-4 z-10">
            <div class="w-3 h-3 rounded-full" :class="indicatorClasses" />
          </div>
        </div>
      </div>

      <!-- Right Action Area -->
      <div class="flex-1 flex items-center justify-end relative ml-24 min-w-0">
        <slot name="right" />
      </div>
    </div>

    <!-- Center Logo -->
    <div
      class="flex bg-csBlue-600 rounded-full p-2 justify-center absolute top-0 left-1/2 transform -translate-x-1/2 z-50"
      @click="onLogoClick"
    >
      <img src="/src/assets/logo-white.svg" class="w-12" alt="">
    </div>
  </div>

  <!-- Logo Shadow Below App Bar -->
  <div
    class="flex rounded-full p-2 justify-center fixed left-1/2 transform -translate-x-1/2 z-0 shadow-md top-0 elevation-4"
  >
    <div class="rounded-full w-12 h-12 bg-csBlue-600" />
  </div>
</template>

