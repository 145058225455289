import { Device } from '@capacitor/device'
import { createI18n, I18n as VueI18n } from 'vue-i18n'
import { LocaleMessages } from '@intlify/core-base'
import { Locale } from '@/types'
import { logError } from '@/common/utils'
import { storageGet } from '@/bootstrap/storage'

// Fallback locale is always loaded.
import de from '@/locales/de.json'

export type MessageSchema = typeof de

// All other locales are loaded dynamically.
const loadedLocales: Locale[] = [Locale.De]

export const availableLocales = [
  Locale.De,
  Locale.En,
  Locale.Fr,
  Locale.It
]

export type I18n = VueI18n<LocaleMessages<MessageSchema, Locale>, LocaleMessages<any, Locale>, LocaleMessages<any, Locale>>

let i18n: I18n

export async function setupI18N () {
  let locale = await resolveLocale()

  if (!availableLocales.includes(locale)) {
    locale = await resolveDeviceLocale()
  }

  const messages = { [Locale.De]: de as MessageSchema } as LocaleMessages<MessageSchema, Locale>

  i18n = createI18n<[MessageSchema], Locale>({
    locale: Locale.De,
    legacy: false,
    globalInjection: true,
    missingWarn: false,
    fallbackWarn: false,
    fallbackLocale: 'de',
    messages,
  })

  // If the active locale is not yet loaded, load it.
  if (!loadedLocales.includes(locale)) {
    await loadLocale(locale)
  }

  return i18n
}

async function setLocale (locale: Locale) {
  //@ts-ignore
  i18n.global.locale.value = locale
}

export async function loadLocale (locale: Locale) {
  // No change.
  if (i18n.global.locale === locale) {
    return
  }

  if (loadedLocales.includes(locale)) {
    return await setLocale(locale)
  }

  const messages = await import(`@/locales/${locale.toLowerCase()}.json`)
  i18n.global.setLocaleMessage(locale, messages)

  await setLocale(locale)
}

async function resolveLocale (): Promise<Locale> {
  // Prefer the setting from the local storage.
  const storedLocale = (await storageGet('locale'))?.toUpperCase()
  if (storedLocale && availableLocales.includes(storedLocale)) {
    return storedLocale.toUpperCase() as Locale
  }

  // Fallback to the device locale.
  let deviceLocale : Locale
  try {
    deviceLocale = await resolveDeviceLocale()
  } catch (e) {
    logError('Failed to get device locale. Using fallback.', e)
    deviceLocale = Locale.De
  }

  return deviceLocale
}

async function resolveDeviceLocale (): Promise<Locale> {
  const value = (await Device.getLanguageCode()).value

  switch (value.toLowerCase()) {
  case 'en':
    return Locale.En
  case 'fr':
    return Locale.Fr
  case 'it':
    return Locale.It
  default:
    return Locale.De
  }
}