<script setup lang="ts">
  import { computed, PropType, ref, watch } from 'vue'
  import { mdiAlertCircleOutline, mdiBellRing, mdiClockOutline, mdiInformationOutline, mdiUndo } from '@mdi/js'
  import { AppCallAction, Call, CallHandlingMode } from '@/types'

  import ContainedButton from '@/components/material/ContainedButton.vue'
  import { useStore } from '@/store'
  import { formatSecondsAsMinutes, formatTime, parseISO } from '@/common/time'
  import { hasElevatedUrgency, isEmergency, useCallActions, useCallStates } from '@/common/call.api'
  import CallStatusIndicator from '@/components/calls/CallStatusIndicator.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { useI18n } from 'vue-i18n'

  const emit = defineEmits(['acceptedByOtherClient', 'actionTriggered'])
  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
    // Initial Call Actions to prevent N+1 queries if this component is rendered in a list.
    callActions: {
      type: Array as PropType<AppCallAction[]>,
      default: () => [],
    },
  })

  const i18n = useI18n()
  const store = useStore()
  const client = computed(() => store.state.client.client)

  const actionsExpanded = ref(false)
  const call = computed<Call | undefined>(() => store.getters['call/byId'](Number(props.id)))
  const actions = ref<AppCallAction[]>(props.callActions ?? [])
  const callColor = computed(() => call.value?.call_type_color ?? 'transparent')
  const callHandlingMode = computed(() => store.state.user.group.call_handling_mode)

  watch(() => props.callActions, newValue => {
    if (newValue) {
      actions.value = newValue
    }
  })

  watch(() => call.value, async (newValue, oldValue) => {
    if (oldValue?.accepted_by_user_id === null && newValue?.accepted_by_user_id !== null && newValue?.accepted_by_user_id !== client.value.id) {
      emit('acceptedByOtherClient', { call: newValue })
    }
  })

  const contextLine = computed(() => {
    if (call.value?.position) {
      return call.value?.position
    }
    if (call.value?.section_name) {
      return call.value?.section_name
    }
    return ''
  })

  const isEmergencyCall = computed(() => call.value ? isEmergency(call.value) : false)
  const animateIcon = computed(() => !isClosed.value && call.value?.urgency && hasElevatedUrgency(call.value))
  const iconStyle = computed(() => {
    if (animateIcon.value) {
      return { backgroundColor: callColor.value, color: '#fff' }
    }
    return { color: callColor.value }
  })

  const truncateActionCount = 3
  const truncateActions = computed(() => actions.value.length > (truncateActionCount + 1) && !actionsExpanded.value)
  const truncatedActionsCount = computed(() => actions.value.length - truncateActionCount)
  const truncatedActions = computed<AppCallAction[]>(() => {
    return truncateActions.value
      ? actions.value.slice(Math.max(actions.value.length - truncateActionCount, 0))
      : actions.value
  })

  const { release, reject, accept, mute, translateAction } = useCallActions(store, i18n)

  function callAction (action: 'release' | 'reject' | 'accept' | 'mute') {
    if (!call.value) {
      return
    }

    emit('actionTriggered', { action, call })

    switch (action) {
    case 'release':
      release(call.value.id)
      break
    case 'reject':
      reject(call.value.id)
      break
    case 'mute':
      mute(call.value.id)
      break
    case 'accept':
      accept(call.value.id)
      break
    }
  }

  const {
    isAccepted,
    isClosed,
    isHandled,
    isAcceptedByMyself,
    isRejectedSinceLastResend,
    secondsUntilEscalation,
    callAgeInSeconds,
    secondsUntilRelease
  } = useCallStates(store, call)
</script>

<template>
  <div class="max-w-lg w-full mx-auto">
    <div class="shadow-lg m-4 rounded overflow-hidden">
      <div class="bg-white relative">
        <div
          class="absolute h-2.5 top-0 w-full z-0"
          :style="{ backgroundColor: call ? call.call_type_color : 'transparent' }"
        />

        <div class="flex px-4 justify-start items-start py-8">
          <!-- Call Type Icon -->
          <div class="flex items-center justify-center relative z-10 w-16">
            <div
              class="rounded-full w-16 h-16 bg-gray-100 flex items-center justify-center"
              :class="[animateIcon ? 'shake' : '']"
              :style="iconStyle"
            >
              <!-- Urgent Call Flash Background for Call Type Icon -->
              <div
                v-if="animateIcon"
                class="absolute flash inset-0 bg-white rounded-full"
              />
              <MdiIcon class="flex-1 fill-current" :size="32" :icon="mdiBellRing" />
            </div>
          </div>

          <div v-if="call" class="px-4 min-w-0 w-full">
            <div class="flex justify-between items-baseline font-semibold text-lg leading-tight">
              <div class="flex-1">
                <div>
                  {{ call.text_short }}
                </div>
                <div v-if="call.text_short !== call.text_long" class="mb-1">
                  {{ call.text_long }}
                </div>
              </div>
              <div v-if="call.call_source_char" class="flex-0 font-normal">
                <div class="text-sm font-bold min-w-5">
                  {{ call.call_source_char }}
                </div>
              </div>
            </div>
            <div class="text-gray-600 text-base leading-tight">
              <p v-if="contextLine">
                {{ contextLine }}
              </p>
              <p>{{ call.call_type_text }}</p>
            </div>
            <CallStatusIndicator :call="call" class="mt-2 text-xs" data-testid="call-status-indicator" />
          </div>
        </div>

        <div class="mb-4 px-4 bg-gray-100 py-4 text-sm">
          <div class="flex justify-start pl-20">
            <div class="flex justify-start leading-none w-24">
              <div class="flex flex-col mr-8">
                <div class="text-3xs text-gray-600 mb-1">
                  {{ $t('common.call_duration') }}
                </div>
                <div class="flex items-center">
                  <div class="text-right mr-2 flex relative top-[-1px]">
                    <MdiIcon :style="{ color: callColor }" :size="16" :icon="mdiClockOutline" />
                  </div>
                  <div>{{ formatSecondsAsMinutes(callAgeInSeconds) }}</div>
                </div>
              </div>
            </div>
            <div v-if="!isAccepted && !isClosed" class="flex justify-start leading-none">
              <div class="flex flex-col mr-8">
                <div class="text-3xs text-gray-600 mb-1">
                  {{ secondsUntilEscalation > 0 ? $t('calldetail.escalate.in') : $t('calldetail.escalate.before') }}
                </div>
                <div class="flex items-center">
                  <div class="text-right mr-2 flex relative top-[-1px]">
                    <MdiIcon
                      :style="{ color: secondsUntilEscalation < 0 ? '#ef4444' : callColor }"
                      :size="16"
                      :icon="mdiAlertCircleOutline"
                    />
                  </div>
                  <div>{{ formatSecondsAsMinutes(secondsUntilEscalation) }}</div>
                </div>
              </div>
            </div>
            <div v-else-if="isAcceptedByMyself && !isClosed" class="flex justify-start leading-none">
              <div class="flex flex-col mr-8">
                <div class="text-3xs text-gray-600 mb-1 whitespace-nowrap">
                  {{ $t('calldetail.release_in') }}
                </div>
                <div class="flex items-center">
                  <div class="text-right mr-2 flex relative top-[-1px]">
                    <MdiIcon :style="{ color: callColor }" :size="16" :icon="mdiUndo" />
                  </div>
                  <div>
                    <template v-if="secondsUntilRelease != null && secondsUntilRelease > 10">
                      {{ formatSecondsAsMinutes(secondsUntilRelease) }}
                    </template>
                    <template v-else>
                      {{ $t('common.soon') }}
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="call" class="container text-xs pb-4">
          <div class="relative text-gray-600">
            <!-- Action History -->
            <div class="space-y-1" @click="actionsExpanded = !actionsExpanded">
              <div class="flex">
                <div class="font-semibold w-16 text-right tabular-nums mr-4 flex-shrink-0">
                  {{ formatTime(parseISO(call.created_at)) }}
                </div>
                <div class="w-24 flex-shrink-0 font-semibold">
                  {{ $t('calldetail.states.opened') }}
                </div>
              </div>
              <div v-if="truncateActions">
                <div class="flex italic">
                  <div class="font-semibold w-16 text-right tabular-nums mr-4 flex-shrink-0">
                    ...&nbsp;
                  </div>
                  <div class="w-40 flex-shrink-0 truncate underline">
                    {{ $t('calldetail.truncated_actions', { count: truncatedActionsCount }) }}
                  </div>
                </div>
              </div>
              <div v-for="action in truncatedActions" :key="action.id">
                <div class="flex">
                  <div class="font-semibold w-16 text-right tabular-nums mr-4 flex-shrink-0">
                    {{ formatTime(parseISO(action.created_at)) }}
                  </div>
                  <div class="w-24 flex-shrink-0 truncate">
                    {{ translateAction(action.action) }}
                  </div>
                  <div v-if="action.username" class="truncate">
                    {{ action.username }}
                  </div>
                </div>
              </div>
              <div v-if="isClosed" class="flex">
                <div v-if="call.closed_at" class="font-semibold w-16 text-right mr-4">
                  {{ formatTime(parseISO(call.closed_at)) }}
                </div>
                <div class="w-40 font-semibold">
                  {{ $t('calldetail.states.closed') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isClosed && !call?.is_presence" class="bg-white">
        <template v-if="isEmergencyCall">
          <div
            class="flex items-center justify-center p-4 leading-tight font-semibold bg-gray-100 text-base text-csBlue-800"
          >
            <div class="flex-shrink-0">
              <MdiIcon :size="24" :icon="mdiInformationOutline" />
            </div>
            <div class="ml-4">
              {{ $t('emergency.cannot_handle') }}
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="callHandlingMode === CallHandlingMode.Full" class="flex">
            <ContainedButton
              v-if="!isHandled"
              id="call-action-reject"
              class="flex-1 flex-shrink-0 bg-white !text-csBlue-600 !rounded-none !py-4"
              @click="callAction('reject')"
            >
              {{ $t('common.actions.reject') }}
            </ContainedButton>
            <ContainedButton
              v-if="!isAccepted"
              id="call-action-accept"
              class="flex-1 flex-shrink-0 bg-csBlue-600 !rounded-none !py-4"
              @click="callAction('accept')"
            >
              {{ $t('common.actions.accept') }}
            </ContainedButton>
            <ContainedButton
              v-if="isAcceptedByMyself"
              id="call-action-release"
              class="flex-1 flex-shrink-0 bg-white !text-csBlue-600 !rounded-none !py-4"
              @click="callAction('release')"
            >
              {{ $t('common.actions.release_manually') }}
            </ContainedButton>
          </div>
          <div v-else class="flex">
            <ContainedButton
              v-if="!isHandled||!isRejectedSinceLastResend"
              id="call-action-reject"
              class="flex-1 flex-shrink-0 bg-csBlue-600 !rounded-none !py-4"
              @click="callAction('mute')"
            >
              {{ $t('common.actions.mute') }}
            </ContainedButton>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .border-indicator
    &:before
      content ""
      position absolute
      left 0
      top 0
      width 1px
      height theme('spacing.8')
      background theme('colors.gray.400')

    &--first:before
      top 18px
      height 14px

  .shake
    animation shake 1.5s infinite

  .flash
    animation flash 1.5s infinite

  @keyframes flash
    from, to
      opacity .3
    50%
      opacity 0

  @keyframes shake
    0%, 50%
      transform rotate(0)
    10%, 20%, 30%, 40%
      transform rotate(-4deg)
    5%, 15%, 25%, 35%, 45%
      transform rotate(4deg)
</style>
