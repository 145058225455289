import client, { ClientState } from '@/store/client.state'
import { InjectionKey } from 'vue'
import { createLogger, createStore, Store, useStore as baseUseStore } from 'vuex'
import app, { AppState } from '@/store/app.state'
import call, { CallState } from '@/store/call.state'
import ui, { UIState } from '@/store/ui.state'
import alert, { AlertState } from '@/store/alert.state'
import user, { UserState } from '@/store/user.state'

export const undoAction = Symbol('undo')

export interface State {
  app: AppState
  call: CallState;
  ui: UIState;
  client: ClientState;
  alert: AlertState;
  user: UserState;
}

const debug = import.meta.env.DEV

export const key: InjectionKey<Store<State>> = Symbol()

const plugins = []
if (debug) {
  plugins.push(createLogger())
}

const store = createStore<State>({
  strict: debug,
  plugins,
  modules: {
    app,
    call,
    ui,
    alert,
    client,
    user,
  },
})

export default store

export function useStore (): Store<State> {
  return baseUseStore<State>(key)
}
