import { storageGet, storageSet } from '@/bootstrap/storage'

export interface ApplicationConfig {
  backend: string | null
  isDemoClient: boolean
  version: string
}

// Storage key for the capacitor SharedStorage plugin.
const sharedStorageClientStateKey = 'ch.caresuite.app.backend'

const config: ApplicationConfig = {
  backend: null,
  isDemoClient: false,
  version: 'develop', // replaced by CI
}

export async function useConfig () {
  if (window.CS_DEMO?.demoClient) {
    config.isDemoClient = true
    let port = ''
    if (window.location.port) {
      port = `:${window.location.port}`
    }
    config.backend = `${window.location.protocol}//${window.location.hostname}${port}`
  }

  if (!config.backend) {
    config.backend = await storageGet(sharedStorageClientStateKey)
  }

  return config
}

// setBackend updates the backend config.
export async function setBackend (backend: string) {
  config.backend = backend
  await storageSet(sharedStorageClientStateKey, backend)
}