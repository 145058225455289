<script lang="ts" setup>
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import FormInput from '@/components/material/FormInput.vue'
  import Logo from '@/components/material/Logo.vue'
  import { onMounted, reactive, ref, watch } from 'vue'
  import { useStore } from '@/store'
  import { useRouter } from 'vue-router'
  import { ValidationError } from '@/common/errors'
  import ErrorDetails from '@/components/debug/ErrorDetails.vue'
  import Snackbar from '@/components/material/Snackbar.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { mdiQrcodeScan } from '@mdi/js'
  import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning'
  import { logError } from '@/common/utils'
  import QRCodeScanner from '@/components/qrcode/QRCodeScanner.vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { loginUser } from '@/common/user.api'
  import { ifNativeElse } from '@/capacitor'
  import { useI18n } from 'vue-i18n'

  const i18n = useI18n()
  const store = useStore()
  const router = useRouter()

  const errorDetails = ref({})
  const errorDetailsVisible = ref(false)
  const qrButtonVisible = ref(false)
  const qrScanningRunning = ref(false)

  const loading = ref(false)
  const error = ref('')
  const errors = ref({})
  const data = reactive({
    username: '',
    password: ''
  })

  onMounted(async () => {
    // Fallback to manual registration if barcode scanner is not supported.
    ifNativeElse(async () => {
      try {
        // Check if QR Code scanning supported.
        const { supported } = await BarcodeScanner.isSupported()
        if (!supported) {
          qrButtonVisible.value = false
          return
        }

        // Check if camera permissions are granted.
        const { camera } = await BarcodeScanner.checkPermissions()

        qrButtonVisible.value = camera !== 'denied'
      } catch (e) {
        qrButtonVisible.value = false
        logError('Barcode scanner not supported:', e)
      }
    }, () => {
      qrButtonVisible.value = false
    })

    if (window.CS_DEMO && window.CS_DEMO.demoUser) {
      data.username = window.CS_DEMO.demoUser
      data.password = '1234'

      await login()
    }
  })

  async function login (overrides: { username?: string, password?: string, group_id?: number } = {}) {
    loading.value = true
    error.value = ''
    errors.value = {}
    errorDetails.value = {}

    try {
      await loginUser(
        store,
        i18n,
        router,
        overrides.username ?? data.username,
        overrides.password ?? data.password,
        overrides.group_id
      )
    } catch (e: any) {
      errorDetails.value = e
      if (e instanceof ValidationError) {
        errors.value = e.fieldErrors
      } else if (e.error) {
        error.value = e.error
      } else {
        error.value = 'Ein unbekannter Fehler ist aufgetreten.'
      }
    } finally {
      loading.value = false
    }
  }

  const debugClickCounter = ref(0)

  function onLogoClick () {
    debugClickCounter.value++
    if (debugClickCounter.value === 5) {
      store.commit('ui/setDebug', true)
      debugClickCounter.value = 0
    }
  }

  function scanQRCode () {
    qrScanningRunning.value = true
    data.username = ''
    data.password = ''
  }

  async function onQRCodeResult (result: string) {
    try {
      const json = JSON.parse(result)

      if ((json.login_token || json.group_id) && json.login_hash) {
        await login({
          username: json.login_token,
          password: json.login_hash,
          group_id: json.group_id
        })
      } else {
        error.value = 'Ungültiger QR-Code.'
        errorDetails.value = json
      }
    } catch (e) {
      error.value = 'Ungültiger QR-Code.'
      errorDetails.value = { error: e }
      return
    } finally {
      qrScanningRunning.value = false
    }
  }

  // Make sure the loading overlay never persists.
  let loadingOverlayTimeout: number | undefined
  watch(loading, (value) => {
    window.clearTimeout(loadingOverlayTimeout)
    if (value) {
      loadingOverlayTimeout = window.setTimeout(() => loading.value = false, 10000)
    }
  })
</script>

<template>
  <div class="bg-csBlue-600 text-white h-screen overflow-y-auto">
    <!-- Loading overlay -->
    <div v-if="loading" class="fixed inset-0 bg-white/75 backdrop-blur-sm z-10 grid place-items-center">
      <div class="space-y-4 text-center">
        <CircularProgress />
        <div class="mt-2 text-xs text-gray-800">
          {{ $t('login.states.logging_in') }}
        </div>
      </div>
    </div>

    <form class="max-w-sm flex flex-col justify-center mx-auto h-screen mt-6 pb-6" @submit.prevent="login()">
      <div class="p-6 pb-12">
        <div>
          <Logo class="w-60 mx-auto text-white" @click.prevent="onLogoClick" />
        </div>
        <div v-if="error" class="bg-red-100 border-red-200 p-4 rounded text-xs text-red-800 text-center mt-12 -mb-12">
          {{ error }}
          <a v-if="errorDetails" href="#" class="underline" @click.prevent="errorDetailsVisible = true">
            <br>
            {{ $t('common.actions.show_details') }}
          </a>
        </div>
        <Snackbar />
        <ErrorDetails
          class="mt-16 -mb-12"
          :visible="errorDetailsVisible"
          :errorDetails="errorDetails"
          @hide="errorDetailsVisible = false"
        />
        <FormInput
          id="username"
          v-model="data.username"
          :label="$t('login.fields.username')"
          class="mt-20"
          inputClass="text-white"
          :errors="errors"
        />
        <FormInput
          id="password"
          v-model="data.password"
          :label="$t('login.fields.password')"
          type="password"
          class="mt-4"
          inputClass="text-white"
          :errors="errors"
        />
        <div class="flex gap-2">
          <ContainedButton
            id="submit"
            class="flex-1 bg-white !text-csBlue-600 w-full mt-12"
            :disabled="loading"
            type="submit"
          >
            {{ $t('login.actions.login') }}
          </ContainedButton>
          <ContainedButton
            v-if="qrButtonVisible"
            class="flex-grow-0 bg-white !text-csBlue-600 mt-12"
            :disabled="loading"
            type="submit"
            @click.prevent="scanQRCode"
          >
            <MdiIcon :icon="mdiQrcodeScan" />
          </ContainedButton>
        </div>
      </div>
    </form>

    <QRCodeScanner :scan="qrScanningRunning" @result="onQRCodeResult" @close="qrScanningRunning = false" />
  </div>
</template>
