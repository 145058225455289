<script setup lang="ts">
  import { mdiArrowLeft } from '@mdi/js'
  import { computed, onMounted, ref, watch } from 'vue'
  import CallDetail from '@/components/calls/CallDetail.vue'
  import { useStore } from '@/store'
  import { useRoute, useRouter } from 'vue-router'
  import { CallListViewMode } from '@/store/ui.state'
  import CallListItemCondensed from '@/components/calls/CallListItemCondensed.vue'
  import SectionHeading from '@/components/layout/SectionHeading.vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { getCallActions } from '@/api/requests'
  import { AppCallAction } from '@/types'
  import { Haptics } from '@capacitor/haptics'
  import { throttle } from '@/common/utils'
  import { useGroupedCalls } from '@/common/call.api'
  import MdiIcon from '@/components/ui/MdiIcon.vue'

  const store = useStore()
  const router = useRouter()
  const route = useRoute()

  const loading = ref(true)
  const callActions = ref<Record<string, AppCallAction[]>>({})

  const { calls } = useGroupedCalls(store)
  const lastCallsUpdate = computed(() => store.state.call.lastUpdate)
  const allOpenCallIDs = computed(() => store.state.call.calls?.filter(call => call.closed_at === null)?.map(call => call.id) ?? [])

  const recentCalls = computed(() => {
    const data = []

    for (const call of calls.value['closed']) {
      const date = call.closed_at ?? 0
      data.push({ ...call, sort_by: +new Date(date) })
    }

    for (const call of calls.value['own']) {
      const date = call.accepted_at ?? 0
      data.push({ ...call, sort_by: +new Date(date) })
    }

    for (const call of calls.value['presences']) {
      data.push({ ...call, sort_by: +new Date(call.created_at) })
    }

    return data.sort((a, b) => b.sort_by - a.sort_by).slice(0, 10)
  })

  onMounted(async () => {
    loading.value = true
    await store.dispatch('call/fetch')
    loading.value = false

    if (calls.value['new'].length > 0 && route.query.popup) {
      try {
        await Haptics.vibrate({ duration: 100 })
      } catch (e) {
        console.warn('Haptics not supported', e)
      }
    }
  })

  const getCallActionsThrottled = throttle(async () => {
    const response = await getCallActions(allOpenCallIDs.value)
    callActions.value = response?.actions ?? []
  }, 5000)

  watch(() => lastCallsUpdate.value, async () => {
    if (allOpenCallIDs.value.length > 0) {
      getCallActionsThrottled()
    }
  })

  // Close the popup when the user manually triggers an action.
  function actionTriggered (args: any) {
    // The action is already processed, there are no more calls, so we can go home.
    if (calls.value['new'].length === 0) {
      goHome()
      return
    }

    // There are multiple calls pending or the action has not been processed yet, don't close the popup.
    if (calls.value['new'].length > 1) {
      return
    }

    // The pending call from the queue is not the one the user has acted on, don't close the popup.
    if (calls.value['new'][0].id !== args.call.value.id) {
      return
    }

    // There is only one call in the queue and the user has acted on it, close the popup.
    goHome()
  }

  function goHome () {
    router.push({ name: 'callList' })
    store.commit('ui/setCallListViewMode', CallListViewMode.Priority)
  }
</script>

<template>
  <div id="call-queue-view" class="bg-gray-100 h-screen-header overflow-y-auto">
    <div v-if="loading" class="flex mt-40 justify-center">
      <CircularProgress />
    </div>
    <div v-else id="call-queue-pending" class="flex flex-col flex-1 pb-4 w-full max-w-lg mx-auto">
      <template v-if="calls['new'].length">
        <div class="mx-4 mt-6">
          <SectionHeading>{{ $t('queue.waiting_for_decision') }}</SectionHeading>
        </div>

        <div class="space-y-4">
          <transition-group name="scale">
            <CallDetail
              v-for="call in calls['new']"
              :id="String(call.id)"
              :key="call.id"
              :isPopup="true"
              :loading="loading"
              :callActions="callActions[call.id] ?? []"
              @actionTriggered="actionTriggered"
            />
          </transition-group>
        </div>
      </template>

      <div v-else class="container mx-auto mt-6" @click="goHome">
        <div
          v-wave
          class="bg-white p-4 rounded text-xs mb-4 mt-2 text-gray-600 flex items-center justify-between cursor-pointer select-none"
        >
          <div class="mr-4">
            <div class="bg-green-500 text-white rounded-full w-10 h-10 flex items-center justify-center">
              <MdiIcon :icon="mdiArrowLeft" />
            </div>
          </div>
          <div class="flex-1">
            <strong class="font-semibold text-green-700">{{ $t('queue.done.title') }}</strong><br>
            <template v-if="$route.query.notification">
              {{ $t('queue.done.notifications.all_done') }}
            </template>
            <template v-else>
              {{ $t('queue.done.notifications.no_new') }}
            </template>
          </div>
        </div>
      </div>

      <div v-if="recentCalls.length > 0" id="call-queue-done">
        <div class="mx-4 mt-6">
          <SectionHeading>{{ $t('queue.last_edited') }}</SectionHeading>
        </div>

        <div class="container">
          <div class="mt-2 pb-2 space-y-2 relative z-10">
            <transition-group name="collapse">
              <CallListItemCondensed
                v-for="call in recentCalls"
                :key="call.id"
                :call="call"
                @click="$router.push({name: 'callDetail', params: {id: call.id}})"
              />
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .scale-enter-from,
  .scale-leave-to
    transform scale(.9)
    opacity 0

  .scale-enter-to,
  .scale-leave
    transform scale(1)
    opacity 1

  .scale-enter-active,
  .scale-leave-active
    transition .3s cubic-bezier(0.4, 0.0, 0.2, 1)
    transition-property transform, opacity
</style>
