<script setup lang="ts">
  import { computed, PropType, ref, watch } from 'vue'
  import { useStore } from '@/store'
  import Modal from '@/components/modal/Modal.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { useI18n } from 'vue-i18n'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { mdiAlert } from '@mdi/js'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { UserClientCall } from '@/types'
  import FormCheckbox from '@/components/material/FormCheckbox.vue'
  import { useUserState } from '@/common/user.api'
  import { useTime } from '@/common/time'

  const store = useStore()
  const i18n = useI18n()

  const loading = ref(false)
  const error = ref<any | null>(null)

  const emit = defineEmits(['close'])
  const props = defineProps({
    visible: {
      type: Boolean,
      default: false,
    },
    clients: {
      type: Array as PropType<UserClientCall[]>,
      default: () => []
    },
  })

  const sortedClients = ref<UserClientCall[]>([])
  const activeClients = ref<number[]>([])
  const time = useTime()

  watch(() => props.clients, () => {
    activeClients.value = props.clients.filter(i => {
      if (!i.client.is_connected) {
        return false
      }

      const { isMuted, isCharging, isInCall } = useUserState(time, ref(i.user))

      return !isMuted.value && !isInCall.value && !isCharging.value
    }).map(i => i.client.id)
  }, {
    immediate: true
  })

  // Populate the sortedClients. Sort clients that are selected first.
  watch(() => props.clients, () => {
    const data = [...props.clients]
    data.sort((a, b) => {
      if (activeClients.value.includes(a.client.id) && !activeClients.value.includes(b.client.id)) {
        return -1
      } else if (!activeClients.value.includes(a.client.id) && activeClients.value.includes(b.client.id)) {
        return 1
      } else {
        return 0
      }
    })
    sortedClients.value = data
  }, { immediate: true })

  const selectedPhoneNumbers = computed(() => {
    return activeClients.value.map(id => props.clients.find(i => i.client.id === id)?.client.phone_number)
  })

  async function initiateCall () {
    loading.value = true
    error.value = null

    try {
      await store.dispatch('call/initiateGroupCall', {
        to: selectedPhoneNumbers.value
      })

      await store.dispatch('ui/showSnackbar', {
        message: i18n.t('people.snackbars.call_success'),
        timeout: 5000,
      })

      emit('close')
    } catch (ex: any) {
      error.value = ex
    } finally {
      loading.value = false
    }
  }
</script>

<template>
  <Modal :visible="visible" :title="$t('people.modals.group_call.title')" @close="$emit('close')">
    <div v-if="loading" class="w-full flex items-center justify-center min-h-[126px]">
      <CircularProgress class="size-8" />
    </div>
    <div v-else class="mb-4 text-sm space-y-4">
      <p>
        {{ $t('people.modals.group_call.text_1') }}
      </p>
      <div class="relative">
        <p class="text-csBlue-800 font-semibold mb-1">
          {{ $t('people.modals.group_call.text_2', [activeClients.length]) }}
        </p>
        <div
          v-if="clients.length"
          class="overflow-y-auto overflow-x-hidden"
          :class="{'pb-12 max-h-[130px] ': clients.length > 4}"
        >
          <FormCheckbox
            v-for="client in sortedClients"
            :key="client.client.id"
            v-model="activeClients"
            :label="client.user.current_display_name ? client.user.current_display_name : client.user.name"
            :value="client.client.id"
          />
        </div>
        <div
          v-if="clients.length > 4"
          class="pointer-events-none bg-gradient-to-t from-white z-20 absolute -bottom-1 h-16 w-full"
        />
      </div>
      <div v-if="error !== null" class="bg-yellow-100 p-2 rounded text-yellow-800 text-sm">
        <div class="flex items-start gap-4">
          <MdiIcon :icon="mdiAlert" class="size-5" />
          <div>
            <strong>
              {{ $t('people.modals.group_call.error') }}
            </strong>
            <div class="mt-2 text-2xs">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <TextButton
        v-if="!loading"
        :disabled="activeClients.length === 0"
        class="bg-csBlue-800 text-white w-full mb-2"
        @click="initiateCall"
      >
        {{ $t('people.modals.group_call.title') }}
      </TextButton>
    </template>
  </Modal>
</template>