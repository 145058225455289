<script setup lang="ts">
  import AppBarAction from '@/components/material/AppBarAction.vue'
  import AppBarTop from '@/components/material/AppBarTop.vue'
  import AppScaffold from '@/components/material/AppScaffold.vue'
  import DrawerHeader from '@/components/material/DrawerHeader.vue'
  import DrawerList from '@/components/material/DrawerList.vue'
  import DrawerListFooter from '@/components/material/DrawerListFooter.vue'
  import DrawerListItem from '@/components/material/DrawerListItem.vue'
  import DrawerSeparator from '@/components/material/DrawerSeparator.vue'
  import Snackbar from '@/components/material/Snackbar.vue'
  import { mdiArrowLeft, mdiBell, mdiExitToApp, mdiMenu, mdiQrcodeScan, mdiStar } from '@mdi/js'
  import { computed, onMounted, ref, watch } from 'vue'
  import { RouteLocation, useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { useConfig } from '@/config'
  import { getAlerts } from '@/api/requests'
  import DrawerDisplayNameEditor from '@/components/user/DrawerDisplayNameEditor.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import QRCodeScanner from '@/components/qrcode/QRCodeScanner.vue'
  import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning'
  import { logError } from '@/common/utils'
  import { loginUser, logoutUser } from '@/common/user.api'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { ifNativeElse } from '@/capacitor'
  import LocaleSelector from '@/components/ui/LocaleSelector.vue'
  import { useI18n } from 'vue-i18n'
  import ConfirmModal from '@/components/modal/ConfirmModal.vue'
  import DrawerMuteControl from '@/components/user/DrawerMuteControl.vue'

  const i18n = useI18n()
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const drawerOpen = ref(false)

  const client = computed(() => store.state.client.client)
  const user = computed(() => store.state.user.user)
  const backRoute = computed(() => (route.meta.backButton) as RouteLocation || '')
  const version = ref('')
  const qrCodeScanningEnabled = ref(false)
  const qrButtonVisible = ref(false)
  const logoutConfirmVisible = ref(false)
  // The locale selector is only visible during development.
  const showLocaleSelector = computed(() => version.value === 'develop')

  onMounted(async () => {
    const config = await useConfig()
    version.value = config.version

    if (config.backend && store.state.user.user.id > 0) {
      await getAlerts(store.commit)
    }

    // Fallback to manual registration if barcode scanner is not supported.
    ifNativeElse(async () => {
      try {
        const { supported } = await BarcodeScanner.isSupported()
        qrButtonVisible.value = supported
      } catch (e) {
        qrButtonVisible.value = false
        logError('Barcode scanner not supported:', e)
      }
    }, () => {
      qrButtonVisible.value = false
    })
  })

  const debugClickCounter = ref(0)

  function onLogoClick () {
    debugClickCounter.value++
    if (debugClickCounter.value === 5) {
      store.commit('ui/setDebug', true)
      debugClickCounter.value = 0
      drawerOpen.value = false
    }
  }

  const qrLoadingOverlayVisible = ref(false)

  async function onQRCodeResult (result: string) {
    qrLoadingOverlayVisible.value = true
    drawerOpen.value = false
    qrCodeScanningEnabled.value = false
    let error = ''
    try {
      const json = JSON.parse(result)

      if ((json.login_token || json.group_id) && json.login_hash) {
        await logoutUser(store)
        await loginUser(store, i18n, router, json.login_token, json.login_hash, json.group_id)
      } else {
        error = i18n.t('common.invalid_qr_code')
      }
    } catch (e: any) {
      if (e?.errors?.username && Array.isArray(e.errors.username)) {
        error = e.errors.username[0].message
      } else {
        error = i18n.t('common.qr_code_error')
      }
      logError('Error processing QR code:', e)
      await router.push({ name: 'login' })
    } finally {
      qrLoadingOverlayVisible.value = false
    }

    if (error) {
      const closeSnackbar = await store.dispatch('ui/showSnackbar', {
        message: error,
        type: 'error'
      })

      setTimeout(closeSnackbar, 4000)
    }
  }

  // Make sure the loading overlay never persists.
  let loadingOverlayTimeout: number | undefined
  watch(qrLoadingOverlayVisible, (value) => {
    window.clearTimeout(loadingOverlayTimeout)
    if (value) {
      loadingOverlayTimeout = window.setTimeout(() => qrLoadingOverlayVisible.value = false, 10000)
    }
  })
</script>

<template>
  <AppScaffold :drawerOpen="drawerOpen" @drawerClose="drawerOpen = false">
    <template #top>
      <AppBarTop>
        <template #left>
          <transition name="rotate">
            <AppBarAction v-if="backRoute" :icon="mdiArrowLeft" @click="$router.push(backRoute)" />
            <AppBarAction v-else :icon="mdiMenu" @click="drawerOpen = true" />
          </transition>
        </template>
      </AppBarTop>
    </template>

    <template #drawer>
      <DrawerHeader class="mb-5">
        <div v-if="showLocaleSelector" class="absolute right-4 bg-gray-100 rounded-full">
          <LocaleSelector />
        </div>
        <img
          id="drawer-logo"
          class="w-12 mb-4"
          src="/src/assets/logo-blue.svg"
          alt=""
          @click="onLogoClick"
        >
        <div class="flex items-center justify-between">
          <div>
            <div id="drawer-username" class="text-xl text-gray-800 font-bold leading-none">
              {{ user.name ? user.name : client.name }}
            </div>
            <div class="text-sm text-gray-500 mt-2 leading-none">
              {{ $t('common.version') }} {{ version }}
            </div>
          </div>
          <button
            v-if="qrButtonVisible"
            v-wave
            class="text-gray-700 -mr-3 p-3 rounded-full"
            @click.prevent="qrCodeScanningEnabled = true"
          >
            <MdiIcon :icon="mdiQrcodeScan" :size="24" />
          </button>
        </div>
      </DrawerHeader>

      <template v-if="store.state.app.features.display_names">
        <DrawerSeparator />

        <DrawerDisplayNameEditor @click="drawerOpen = false" />
      </template>

      <DrawerSeparator class="mb-5" />

      <DrawerList>
        <DrawerListItem :route="{ name: 'callList' }" :icon="mdiBell" @click="drawerOpen = false">
          {{ $t('calllist.title') }}
        </DrawerListItem>
        <DrawerListItem
          v-if="store.state.app.features.subscriptions && store.state.app.features.subscriptions_user_defined"
          :route="{ name: 'subscription' }"
          :icon="mdiStar"
          @click="drawerOpen = false"
        >
          {{ $t('common.priorities') }}
        </DrawerListItem>
      </DrawerList>

      <DrawerListFooter :class="{'pb-1': store.state.app.features.logout_disabled}">
        <DrawerMuteControl @done="drawerOpen = false" />

        <template v-if="!store.state.app.features.logout_disabled">
          <DrawerSeparator class="my-2" />
          <DrawerListItem
            id="drawer-nav-logout"
            :icon="mdiExitToApp"
            @click="drawerOpen = false; logoutConfirmVisible = true"
          >
            {{ $t('common.actions.logout') }}
          </DrawerListItem>
        </template>
      </DrawerListFooter>
    </template>

    <router-view v-slot="{ Component }">
      <transition name="zoom" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </AppScaffold>

  <Snackbar />

  <QRCodeScanner :scan="qrCodeScanningEnabled" @result="onQRCodeResult" @close="qrCodeScanningEnabled = false" />

  <ConfirmModal
    :visible="logoutConfirmVisible"
    :title="$t('common.actions.logout')"
    :actionLabel="$t('common.actions.logout')"
    @close="logoutConfirmVisible = false"
    @confirm="router.push({ name: 'logout' })"
  >
    <p class="font-semibold">
      {{ $t('common.modals.confirm_logout.text_1') }}
    </p>
    <p class="mt-4">
      {{ $t('common.modals.confirm_logout.text_2') }}
    </p>
  </ConfirmModal>

  <!-- Loading overlay -->
  <div
    v-if="qrLoadingOverlayVisible"
    class="fixed inset-0 bg-white/75 backdrop-blur-sm grid place-items-center z-20"
  >
    <div class="space-y-4 text-center">
      <CircularProgress />
      <div class="mt-2 text-xs text-gray-800">
        {{ $t('common.states.processing') }}
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
  .zoom-enter-from
    transform scale(.975)
    opacity 0

  .zoom-enter-to
    transform scale(1)
    opacity 1

  .zoom-enter-active
    @apply absolute inset-0 z-20
    transition ease-out .1s
    transition-property transform, opacity

  .zoom-leave-from
    opacity 1

  .zoom-leave-to
    opacity 0

  .zoom-leave-active
    transition opacity .05s

  .rotate-enter-from
    transform rotate(180deg)
    opacity 0

  .rotate-enter-to
    transform rotate(0deg)
    opacity 1

  .rotate-enter-active
    transition .2s ease-out
    transition-property opacity, transform
</style>
