<template>
  <button class="flex-1 p-2.5 outline-none">
    <span class="flex flex-col items-center" :class="[active ? 'text-csBlue-400' : 'text-gray-400']">
      <span
        v-if="counter > 0"
        class="absolute z-10 ml-7 -mt-1 text-2xs font-bold rounded-full w-4 h-4 flex items-center justify-center"
        :class="counterClasses"
      >
        {{ counter }}
      </span>
      <span v-if="useIcon" class="block mb-1">
        <span
          class="rounded-full w-12 flex items-center justify-center transition-colors duration-200"
          :class="[active ? 'bg-csBlue-100' : 'bg-transparent']"
        >
          <MdiIcon class="fill-current w-5" :icon="useIcon" />
        </span>
      </span>
      <span>
        <slot />
      </span>
    </span>
  </button>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue'

  import MdiIcon from '@/components/ui/MdiIcon.vue'

  export default defineComponent({
    name: 'BottomNavigation',
    components: { MdiIcon },
    props: {
      icon: {
        type: String,
        default: '',
      },
      iconActive: {
        type: String,
        default: '',
      },
      active: {
        type: Boolean,
        default: false,
      },
      counter: {
        type: Number,
        default: 0,
      },
      counterClasses: {
        type: [String, Array],
        default: 'bg-red-500 text-white',
      }
    },
    setup (props) {
      return {
        useIcon: computed(() => {
          if (!props.icon) {
            return false
          }

          if (!props.active) {
            return props.icon
          }

          return props.iconActive || props.icon
        })
      }
    }
  })
</script>
