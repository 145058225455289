<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { useStore } from '@/store'
  import { useRoute, useRouter } from 'vue-router'
  import { Call } from '@/types'
  import { differenceInSeconds, formatDistanceStrict, parseISO, useTime } from '@/common/time'
  import SwipeToAction from '@/components/ui/SwipeToAction.vue'
  import { EmergencyNotification } from '@/capacitor'
  import { mdiAlertOutline, mdiBellAlert } from '@mdi/js'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { logError } from '@/common/utils'

  const loading = ref(true)
  const store = useStore()

  const route = useRoute()
  const router = useRouter()

  onMounted(fetch)

  async function fetch () {
    loading.value = true
    try {
      await store.dispatch('call/fetch')
    } catch (e) {
      logError('failed to fetch calls', e)
    } finally {
      loading.value = false
    }
  }

  const call = computed<Call | undefined>(() => store.getters['call/byId'](Number(route.params.id)))

  const contextLine = computed(() => {
    if (call.value?.position) {
      return call.value?.position
    }
    if (call.value?.section_name) {
      return call.value?.section_name
    }
    return ''
  })

  const callOpenedAt = computed(() => parseISO(call.value?.created_at ?? new Date().toDateString()))
  const callAgeInSeconds = computed(() => {
    return differenceInSeconds(useTime().value.now, callOpenedAt.value)
  })
  const callAge = computed(() => {
    return formatDistanceStrict(callOpenedAt.value, useTime().value.now, {
      addSuffix: true,
    })
  })

  const minCallAgeToDismiss = 10
  const canDismiss = computed(() => callAgeInSeconds.value >= minCallAgeToDismiss)

  // Close the activity when the call is closed.
  watch(call, (newCall) => {
    if (newCall?.closed_at !== null) {
      close()
    }
  })

  async function close () {
    loading.value = true

    await router.push({ name: 'callList' })

    await EmergencyNotification.closeActivity()
  }
</script>

<template>
  <div class="bg-gray-100 overflow-y-auto h-screen flex flex-col">
    <div v-if="!loading" class="border-[20px] border-animation flex-1 flex items-center justify-center">
      <div class="p-5 flex flex-col space-y-4 text-center">
        <div class="mx-auto text-csBlue-500">
          <MdiIcon
            :icon="call ? mdiBellAlert : mdiAlertOutline"
            :size="64"
          />
        </div>
        <div v-if="call" class="space-y-2 text-3xl">
          <h1 class="text-4xl font-bold uppercase tracking-wider">
            {{ call.call_type_text }}
          </h1>

          <div class="font-semibold leading-tight">
            <div>
              {{ call.text_short }}
            </div>
            <div v-if="call.text_short !== call.text_long" class="mb-1">
              {{ call.text_long }}
            </div>
          </div>

          <div v-if="contextLine" class="text-black leading-tight">
            {{ contextLine }}
          </div>

          <div class="text-gray-700">
            {{ callAge }}
          </div>

          <SwipeToAction :disabled="!canDismiss" class="!mt-16" @swiped="close()">
            <template v-if="!canDismiss">
              <i18n-t keypath="emergency.seconds_until_swipe_to_dismiss">
                <template #seconds>
                  <strong>{{ minCallAgeToDismiss - callAgeInSeconds }}</strong>
                </template>
              </i18n-t>
            </template>
            <template v-else>
              {{ $t('common.actions.swipe_to_dismiss') }}
            </template>
          </SwipeToAction>
        </div>
        <div v-else class="space-y-8 flex flex-col items-center">
          <div class="space-y-4">
            <p class="text-2xl">
              <strong>{{ $t('emergency.new_emergency') }}</strong>
            </p>
            <p>
              {{ $t('emergency.errors.unable_to_load') }}
            </p>
            <p>
              {{ $t('emergency.errors.proof_network') }}
            </p>
          </div>
          <div class="!mt-12 flex flex-col justify-center space-y-2">
            <ContainedButton class="bg-csBlue-500 w-[200px]" @click.prevent="fetch()">
              {{ $t('common.actions.retry') }}
            </ContainedButton>
            <TextButton @click.prevent="close()">
              {{ $t('common.actions.close') }}
            </TextButton>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex items-center justify-center h-screen">
      <div class="flex flex-col items-center">
        <CircularProgress />
        <div class="mt-6 text-2xs text-gray-500">
          {{ $t('common.loading') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .border-animation {
    animation: border-alarm 1s infinite;
  }

  @keyframes border-alarm {
    0%, 100% {
      border-color: theme('colors.pink.300');
    }

    50% {
      border-color: theme('colors.pink.500');
    }
  }
</style>