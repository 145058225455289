<script setup lang="ts">
  import { computed, PropType } from 'vue'

  defineEmits(['hide'])

  const props = defineProps({
    errorDetails: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  })

  const formatted = computed(() => {
    const data = { ...props.errorDetails }
    if ((props.errorDetails?.error as any)?.response) {
      data.response = (props.errorDetails.error as any).response
    }
    return JSON.stringify(data, null, 2)
  })
</script>

<template>
  <div
    v-if="visible"
    class="text-left text-3xs text-orange-600 relative bg-orange-100 p-4 border-orange-400 rounded"
  >
    <div
      class="bg-orange-100 border-b inline-block border-orange-400 absolute mt-3 mr-4 right-0 top-0 text-3xs text-orange-400"
      @click.prevent="$emit('hide')"
    >
      Ausblenden
    </div>

    <div class="max-h-[200px] overflow-auto">
      <pre class="block">{{ formatted }}</pre>
    </div>
  </div>
</template>