<script setup lang="ts">
  import { MaybeComputedElementRef, useElementBounding } from '@vueuse/core'
  import { computed, ref, watch } from 'vue'
  import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { mdiClose } from '@mdi/js'
  import type { PluginListenerHandle } from '@capacitor/core'

  const emit = defineEmits(['result', 'close'])
  const props = defineProps({
    scan: {
      type: Boolean,
      default: false,
    },
  })

  const isScanning = ref(false)

  watch(() => props.scan, async (value) => {
    if (value) {
      await startScanning()
    } else {
      await stopScanning()
    }
  }, { immediate: true })

  const qrSquare = ref(null)
  const qrSquareBounding = useElementBounding(qrSquare as unknown as MaybeComputedElementRef)

  // The coordinates of the QR detection area, scaled to the device pixel ratio.
  const qrDetectionArea = computed(() => {
    const scaledRect = {
      left: qrSquareBounding.left.value * window.devicePixelRatio,
      right: qrSquareBounding.right.value * window.devicePixelRatio,
      top: qrSquareBounding.top.value * window.devicePixelRatio,
      bottom: qrSquareBounding.bottom.value * window.devicePixelRatio,
      width: qrSquareBounding.width.value * window.devicePixelRatio,
      height: qrSquareBounding.height.value * window.devicePixelRatio,
    }

    return [
      [scaledRect.left, scaledRect.top],
      [scaledRect.left + scaledRect.width, scaledRect.top],
      [scaledRect.left + scaledRect.width, scaledRect.top + scaledRect.height],
      [scaledRect.left, scaledRect.top + scaledRect.height],
    ]
  })

  let listener: PluginListenerHandle | null = null

  async function startScanning () {
    document.querySelector('body')?.classList.add('barcode-scanner-active')
    isScanning.value = true

    listener = await BarcodeScanner.addListener(
      'barcodeScanned',
      async result => {
        const cornerPoints = result.barcode.cornerPoints
        if (!cornerPoints) {
          return
        }

        // Make sure the result is inside the QR square.
        if (
          qrDetectionArea.value[0][0] > cornerPoints[0][0] ||
          qrDetectionArea.value[0][1] > cornerPoints[0][1] ||
          qrDetectionArea.value[1][0] < cornerPoints[1][0] ||
          qrDetectionArea.value[1][1] > cornerPoints[1][1] ||
          qrDetectionArea.value[2][0] < cornerPoints[2][0] ||
          qrDetectionArea.value[2][1] < cornerPoints[2][1] ||
          qrDetectionArea.value[3][0] > cornerPoints[3][0] ||
          qrDetectionArea.value[3][1] < cornerPoints[3][1]
        ) {
          return
        }

        emit('result', result.barcode.displayValue)

        await stopScanning()
      }
    )

    try {
      await BarcodeScanner.startScan()
    } catch (e) {
      console.error('Failed to start scanning', e)

      await stopScanning()
    }
  }

  async function stopScanning () {
    if (!isScanning.value) {
      return
    }

    document.querySelector('body')?.classList.remove('barcode-scanner-active')
    isScanning.value = false

    await BarcodeScanner.stopScan()

    if (listener) {
      await listener.remove()
    }
  }
</script>

<template>
  <teleport to="body">
    <div v-if="isScanning" class="qr-scan-overlay fixed inset-0 z-40 place-items-center" @click="$emit('close')">
      <div ref="qrSquare" class="aspect-square w-[75vw] border-2 border-yellow-500 rounded shadow-lg" />

      <div class="z-50 absolute right-5 top-5 text-white">
        <MdiIcon :icon="mdiClose" :size="32" />
      </div>
    </div>
  </teleport>
</template>

<style lang="stylus">
  // Hide all elements during scanning
  .barcode-scanner-active #app-host
    visibility hidden

  .qr-scan-overlay
    display none

    .barcode-scanner-active &
      display grid
</style>
