<script setup lang="ts">

  import { mdiBellOff, mdiBellRing } from '@mdi/js'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from '@/store'
  import { computed, ref, watch } from 'vue'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import MutedUntilText from '@/components/user/MutedUntilText.vue'

  const emit = defineEmits(['done'])

  const i18n = useI18n()
  const store = useStore()

  const muteValue = ref(null)

  const isMuted = computed(() => store.getters['user/mutedUntil'] !== null)
  watch(() => isMuted.value, (newValue) => {
    if (!newValue) {
      muteValue.value = null
    }
  })

  const availableMuteTimes = {
    '5': '5 ' + i18n.t('common.minutes'),
    '15': '15 ' + i18n.t('common.minutes'),
    '30': '30 ' + i18n.t('common.minutes'),
    '60': '1 ' + i18n.t('common.hour'),
    '120': '2 ' + i18n.t('common.hours'),
    '-1': i18n.t('common.permanent'),
  }

  async function mute (e: Event) {
    emit('done')

    await store.dispatch('user/muteNotifications', (e.target as HTMLSelectElement).value)
  }

  async function unmute () {
    await store?.dispatch('user/muteNotifications', 0)
  }
</script>

<template>
  <div class="px-4 mt-1 pb-1 text-sm">
    <transition name="zoom" mode="out-in">
      <div v-if="!isMuted">
        <div class="text-gray-600 mb-2 flex items-center" />
        <div class="relative">
          <div v-if="!muteValue" class="absolute top-0 left-16 flex items-center leading-tight h-full text-gray-600 pointer-events-none">
            {{ $t('mute.actions.pause') }}
          </div>
          <select v-model="muteValue" class="w-full px-4 pl-16 h-12 text-gray-600 bg-white focus:bg-gray-100 focus:outline-none font-semibold leading-tight rounded appearance-none whitespace-pre-wrap" @change="mute">
            <option value hidden disabled selected />
            <option v-for="(label, value) in availableMuteTimes" :key="value" :value="value">
              {{ label }}
            </option>
          </select>
          <!-- Icon Left -->
          <div class="absolute w-10 left-3 top-0 h-full flex items-center">
            <MdiIcon :icon="mdiBellOff" class="size-6 mr-2 text-gray-500" />
          </div>
        </div>
      </div>
      <div v-else @click="unmute">
        <ContainedButton class="bg-csBlue-400 w-full">
          <div class="flex items-center">
            <MdiIcon :icon="mdiBellRing" class="size-4 mr-4 -ml-1" />
            <span>
              {{ $t('mute.actions.resume') }}
            </span>
          </div>
        </ContainedButton>
        <p class="text-xs text-center mt-2 text-gray-500">
          <MutedUntilText />
        </p>
      </div>
    </transition>
  </div>
</template>