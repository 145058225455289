<template>
  <div class="bg-gray-100 overflow-y-auto h-screen-header">
    <div class="mx-4 mt-6">
      <SectionHeading>{{ $t('calldetail.title') }}</SectionHeading>
    </div>
    <CallDetail
      v-if="!loading && $route.params.id"
      :id="String($route.params.id)"
      :callActions="callActions"
      @actionTriggered="onActionTriggered"
    />
    <div v-else class="flex mt-40 justify-center">
      <CircularProgress />
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import CallDetail from '@/components/calls/CallDetail.vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import SectionHeading from '@/components/layout/SectionHeading.vue'
  import { useStore } from '@/store'
  import { CallListViewMode } from '@/store/ui.state'
  import { useRoute, useRouter } from 'vue-router'
  import { getCallActions } from '@/api/requests'
  import { throttle } from '@/common/utils'
  import { AppCallAction } from '@/types'

  export default defineComponent({
    name: 'CallDetailView',
    components: { CallDetail, CircularProgress, SectionHeading },
    setup () {
      const loading = ref(true)
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const callActions = ref<AppCallAction[]>([])
      const lastCallsUpdate = computed(() => store.state.call.lastUpdate)

      onMounted(async () => {
        loading.value = true
        await store.dispatch('call/fetch')
        loading.value = false
      })

      async function fetchCallActions () {
        if (!route.params.id) {
          return
        }
        const id = route.params.id.toString()
        const response = (await getCallActions([id])).actions as Record<string, AppCallAction[]>
        if (response.hasOwnProperty(id)) {
          callActions.value = response[id] ?? []
        }
      }

      const fetchCallActionsThrottled = throttle(fetchCallActions, 5000)

      watch(() => lastCallsUpdate.value, async () => {
        fetchCallActionsThrottled()
      })

      function onActionTriggered () {
        router.push({ name: 'callList' })
        store.commit('ui/setCallListViewMode', CallListViewMode.Priority)
      }

      return {
        loading,
        callActions,
        onActionTriggered,
      }
    }
  })
</script>
