<script setup lang="ts">
  import { useStore } from '@/store'
  import { onMounted, ref, watch } from 'vue'
  import { Capacitor } from '@capacitor/core'
  import { useI18n } from 'vue-i18n'
  import { PromptPermissionsMode } from '@/store/ui.state'
  import { State } from '@/capacitor'

  const i18n = useI18n()
  const store = useStore()
  const prompted = ref(false)

  onMounted(async () => {
    await promptMissingPermissions()
  })

  // Re-prompt the permissions if the team view was enabled.
  watch(() => store.state.app.features.team_view, (enabled) => {
    if (enabled) {
      store.commit('app/clearPermissionsPromptCache')
      prompted.value = false
      promptMissingPermissions()
    }
  })

  async function promptMissingPermissions () {
    const { isRunningTests } = await State.isRunningTests()

    if (prompted.value || !Capacitor.isNativePlatform() || isRunningTests) {
      return
    }

    prompted.value = true

    // Ask for READ_PHONE_STATE permission if the team view is enabled.
    if (store.state.app.features.team_view || store.state.ui.promptPermissions >= PromptPermissionsMode.ForcePrompt) {
      await store.dispatch('app/askForPermission', {
        permission: 'android.permission.READ_PHONE_STATE',
        message: i18n.t('permissions.prompts.read_phone_state'),
        action: i18n.t('common.actions.allow')
      })
    }

    // Only ask for SCHEDULE_EXACT_ALARM permission on registration view.
    // The permission is not strictly required and there are old installations
    // that have not been prompted for it.
    if (store.state.ui.promptPermissions >= PromptPermissionsMode.ForcePrompt) {
      await store.dispatch('app/askForPermission', {
        permission: 'android.permission.SCHEDULE_EXACT_ALARM',
        message: i18n.t('permissions.prompts.schedule_exact_alarm'),
        action: i18n.t('common.actions.allow'),
        min_sdk_version: 31,
      })
    }

    await store.dispatch('app/askForPermission', {
      permission: 'android.permission.USE_FULL_SCREEN_INTENT',
      message: i18n.t('permissions.prompts.use_full_screen_intent'),
      action: i18n.t('common.actions.allow'),
      min_sdk_version: 34,
    })

    await store.dispatch('app/askForPermission', {
      permission: 'android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS',
      message: i18n.t('permissions.prompts.ignore_battery_optimizations'),
      action: i18n.t('common.actions.allow'),
      min_sdk_version: 23,
    })

    await store.dispatch('app/askForPermission', {
      permission: 'android.permission.POST_NOTIFICATIONS',
      message: i18n.t('permissions.prompts.post_notifications'),
      action: i18n.t('common.actions.allow'),
      min_sdk_version: 33,
    })
  }
</script>

<script lang="ts">
  export default {
    render () {
      return []
    }
  }
</script>

