<template>
  <div class="flex items-center bg-white text-gray-800 elevation-8 relative z-20">
    <div class="flex items-center w-full max-w-5xl mx-auto leading-none text-2xs">
      <!-- Use BottomNavigationButton here -->
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'BottomNavigation',
    components: {},

  })
</script>

<style lang="stylus" scoped>

</style>