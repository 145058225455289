import { State, useStore } from '@/store'
import {
  Alert,
  AppCallAction,
  Call,
  Client,
  DisplayName,
  Features,
  Group,
  License,
  Nullable,
  Section,
  SystemTreeNode,
  Timeouts,
  User,
  UserClientCall
} from '@/types'
import { http } from '@/bootstrap/http'
import { Permissions } from '@/store/app.state'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { logError, lottery, throttle } from '@/common/utils'
import { logoutUser as logoutUserAction } from '@/common/user.api'
import { useTime } from '@/common/time'
import { Commit, Store } from 'vuex'
import { ConflictError, ValidationError, ValidationFieldError } from '@/common/errors'
import { Router } from 'vue-router'

// request params is the base interface for all requests.
interface requestParams {
  [key: string]: unknown
}

// response is the base response returned by the backend.
interface response {
  [key: string]: unknown

  success: boolean
}

// request runs a request configuration and returns the data property directly.
export async function request<T = any> (req: AxiosRequestConfig, params: Record<string, unknown> = {}) {
  req.data = params

  if (http === undefined) {
    throw new Error('http is not initialized')
  }

  try {
    const response = await http.request<T>(req)

    return response?.data
  } catch (error: any) {
    switch (true) {
    case error?.code === 'ECONNABORTED':
      logError('request timed out', error)
      break
    case error.response !== undefined && error.response !== null:
      logError('http response error: ', error)
      await handleErrorResponse(error.response, error)
      break
    case error.request !== undefined && error.request !== null:
      logError('http request error: ', error)
      break
    default:
      logError('http error: ', error)
    }

    throw error
  }
}

async function handleErrorResponse (response: AxiosResponse, error: any) {
  switch (response?.status) {
  case 401: // Unauthorized
    const store = useStore()
    if (store) {
      await store.dispatch('client/setSecret', '')
    } else {
      // trigger a hard reload if we have no store.
      // This way, the next request that fails will have the store since it's initiated through a vue component
      window.location.reload()
    }
    break
  case 409: // Conflict
    throw new ConflictError(response.data)
  case 422: // Unprocessable Entity
    throw new ValidationError(response.data.errors as Record<string, ValidationFieldError[]>)
  default:
    throw error
  }
}

//
// Nodes
//

interface nodeResponse extends response {
  nodes: SystemTreeNode[]
}

export async function getNodes () {
  return request<nodeResponse>({
    method: 'GET',
    url: '/nodes'
  })
}

//
// Clients
//

interface registerDeviceParams extends requestParams {
  pairing_token: string;
  messaging_token: string;
  name: string;
  phone_number: string;
  permissions: string[];
}

interface registerClientResponse extends response {
  client: Client
  license: License
}

export async function registerClient (params: registerDeviceParams) {
  return request<registerClientResponse>({
    method: 'POST',
    url: '/clients'
  }, params)
}

interface loginUserParams extends requestParams {
  username: string;
  password: string;
  client_id: number;
}

interface loginClientResponse extends response {
  user: User
  group: Group
  client: Client
  license: License
  features: Features
  timeouts: Timeouts
}

export async function loginClient (params: loginUserParams) {
  return request<loginClientResponse>({
    method: 'POST',
    url: '/clients/login'
  }, params)
}

export interface checkInClientParams extends requestParams {
  messaging_token?: string;
  permissions?: Permissions
}

interface checkInClientResponse extends response {
  client: Client
  user: User
  group: Group
  license: License
  features: Features
  timeouts: Timeouts
}

// checkInClientThrottled is a throttled version of checkInClient.
// It is used to prevent multiple check-in calls when the application launches and/or
// becomes visible at the same time.
export const checkInClientThrottled = throttle(checkInClient, 5000)

export async function checkInClient (params: checkInClientParams, router: Router, store?: Store<State>) {
  // Only sometimes query and send the permissions. The check-in happens very often,
  // so we don't want to slow the process down too much.
  if (store && !params.permissions && lottery(1, 20)) {
    params.permissions = await store.dispatch('app/getGrantedPermissions')
  }

  const response = await request<checkInClientResponse>({
    method: 'PATCH',
    url: '/clients'
  }, params)

  if (store && response) {
    // If there is no longer a user available, log out.
    if (!response.user || response.user.id === 0) {
      await logoutUserAction(store)

      await router.push({ name: 'login' })
    } else if (response.client) {
      await store.dispatch('client/setClient', response.client)
      await store.dispatch('user/setUserAndGroup', { user: response.user, group: response.group })
      store.commit('ui/setTimeouts', response.timeouts)
      store.commit('app/setFeatures', response.features)
      store.commit('app/setLicense', response.license)
    }

    store.commit('ui/promptPermissions')
  }

  return response
}

export async function deleteCurrentClient () {
  return request({
    method: 'DELETE',
    url: '/clients/me'
  })
}

//
// User
//

export interface patchUserParams extends requestParams {
  muted_until?: number;
}

interface patchUserResponse extends response {
  user: User
}

export async function patchUser (params: patchUserParams) {
  return await request<patchUserResponse>({
    method: 'PATCH',
    url: '/users'
  }, params)
}

export async function logoutUser () {
  return await request<checkInClientResponse>({
    method: 'PATCH',
    url: '/clients/logout'
  }, { confirm: true })
}

export async function saveSubscription (subscription: number[]) {
  return request<response>({
    method: 'PUT',
    url: `/users/subscription`
  }, { subscription })
}

//
// Calls
//
interface callsResponse extends response {
  calls: Call[]
  rejected: Record<number, string>
  released: Record<number, string>

  alerts: Alert[]
  confirmed_alerts: Record<number, string>
}

export async function getCalls () {
  return request<callsResponse>({
    method: 'GET',
    url: '/calls'
  })
}

interface callActionsResponse extends response {
  actions: Record<string, AppCallAction[]>
}

export async function getCallActions (id: (number | string)[]) {
  if (!id.length) {
    return { actions: {} }
  }
  return request<callActionsResponse>({
    method: 'GET',
    url: `/calls/actions?id=` + id.join(',')
  })
}

export interface getCallResponse extends response {
  call: Call,
}

export async function getCall (id: number) {
  return request<getCallResponse>({
    method: 'GET',
    url: `/calls/${id}`
  })
}

interface patchCallParams extends requestParams {
  action: 'accept' | 'reject' | 'release' | 'mute' | 'callback'
}

export interface patchCallResponse extends response {
  calls: Call[]
  call: Call,
  owner: Client,
}

async function patchCall (id: number, params: patchCallParams) {
  return request<patchCallResponse>({
    method: 'PATCH',
    url: `/calls/${id}`
  }, params)
}

export async function rejectCall (id: number) {
  return await patchCall(id, { action: 'reject' })
}

export async function muteCall (id: number) {
  return await patchCall(id, { action: 'mute' })
}

export async function releaseCall (id: number) {
  return await patchCall(id, { action: 'release' })
}

export async function initiateCallback (id: number) {
  return await patchCall(id, { action: 'callback' })
}

export interface initiateGroupCallRequest extends requestParams {
  to: string[]
}

export interface initiateGroupCallResponse extends response {
  clients: Client[]
}

export async function initiateGroupCall (params: initiateGroupCallRequest) {
  return request<initiateGroupCallResponse>({
    method: 'POST',
    url: `/calls/groupcall`
  }, params)
}

export async function acceptCall (id: number) {
  return await patchCall(id, { action: 'accept' })
}

interface patchNotificationParams extends requestParams {
  confirmed_at?: string
  opened_at?: string
}

//
// Notifications
//
export async function confirmNotification (id: number, markOpen = true) {
  const now = nowRFC3339()
  const params: patchNotificationParams = {
    confirmed_at: now
  }
  if (markOpen) {
    params.opened_at = now
  }

  return request({
    method: 'PATCH',
    url: `/notifications/${id}`,
  }, params)
}

export async function openNotification (id: number) {
  return request({
    method: 'PATCH',
    url: `/notifications/${id}`,
  }, {
    opened_at: nowRFC3339()
  })
}

const nowRFC3339 = () => (new Date(useTime().value.now)).toISOString()

//
// Alerts
//
interface alertsResponse extends response {
  alerts: Alert[]
  confirmed: Record<number, string>
}

export async function getAlerts (commit: Nullable<Commit> = null) {
  const response = await request<alertsResponse>({
    method: 'GET',
    url: '/alerts'
  })
  if (commit && response && response.alerts && response.confirmed) {
    commit('alert/setAlerts', response.alerts)
    commit('alert/setConfirmedAlerts', response.confirmed)
  }
  return response
}

interface confirmAlertResponse extends response {
  alert: Alert
}

export async function confirmAlert (id: number) {
  return request<confirmAlertResponse>({
    method: 'PATCH',
    url: `/alerts/${id}`,
  }, {
    action: 'confirm',
  })
}

//
// Display Names
//
interface displayNamesResponse extends response {
  display_names: DisplayName[]
}

export async function getDisplayNames () {
  return request<displayNamesResponse>({
    method: 'GET',
    url: '/users/displaynames'
  })
}

interface registerDisplayNamesParams extends requestParams {
  display_name: string
}

export async function registerDisplayName (params: registerDisplayNamesParams) {
  return request<response>({
    method: 'POST',
    url: '/users/displaynames',
  }, params)
}

interface removeDisplayNamesParams extends requestParams {
  display_name: string
}

export async function removeDisplayName (params: removeDisplayNamesParams) {
  return request<response>({
    method: 'DELETE',
    url: '/users/displaynames',
  }, params)
}

//
// Team
//
interface usersResponse extends response {
  data: UserClientCall[]
  groups: Group[]
}

export async function getUsers () {
  return request<usersResponse>({
    method: 'GET',
    url: `/users`
  })
}

//
// SystemTree
//
interface roomsResponse extends response {
  rooms: SystemTreeNode[]
  sections: Section[]
  subscription: number[]
}

export async function getRooms () {
  return request<roomsResponse>({
    method: 'GET',
    url: `/systemtree/rooms`
  })
}

//
// SOS
//
export async function triggerSOS () {
  return request({ method: 'POST', url: '/sos' })
}

export async function cancelSOS () {
  return request({ method: 'DELETE', url: '/sos' })
}