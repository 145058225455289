export interface ValidationErrorBag {
  [key: string]: string;
}

export interface ValidationFieldError {
  key: string;
  message: string;
  data: Record<string, string>
}

// ValidationError contains form validation errors.
export class ValidationError extends Error {
  public errors: Record<string, ValidationFieldError[]>
  public fieldErrors: ValidationErrorBag

  public constructor (errors: Record<string, ValidationFieldError[]>) {
    super('Validation failed:' + JSON.stringify(errors))
    this.errors = errors
    this.fieldErrors = this.mapErrors(errors)
  }

  private mapErrors (errors: Record<string, ValidationFieldError[]>) {
    const result: ValidationErrorBag = {}
    for (const key in errors) {
      result[key] = errors[key][0].message
    }
    return result
  }

}

// ConflictError is thrown when the response from the backend has an unexpected format.
export class ConflictError extends Error {
  public data: unknown

  public constructor (data: unknown) {
    super('conflict error: ' + JSON.stringify(data))
    this.data = data
  }
}
