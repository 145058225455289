<template>
  <div class="relative z-20 uppercase text-xs font-semibold text-center text-csBlue-600">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SectionHeading',
  })
</script>