<script setup lang="ts">
  import { mdiFilter } from '@mdi/js'
  import CallListFilterModal from '@/components/modal/CallListFilterModal.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import SectionHeading from '@/components/layout/SectionHeading.vue'
  import { computed, PropType, ref, toRef } from 'vue'
  import { Alert, Call } from '@/types'
  import AlertListItem from '@/components/alerts/AlertListItem.vue'
  import CallListItem from '@/components/calls/CallListItem.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import { useStorage } from '@vueuse/core'
  import { callHistoryFilterStorageKey, CallListFilter, defaultCallHistoryFilter } from '@/common/filter.api'
  import TextButton from '@/components/material/TextButton.vue'
  import { useSimplePagination } from '@/common/pagination.api'

  const props = defineProps({
    alerts: {
      type: Array as PropType<Alert[]>,
      required: true,
    },
    calls: {
      type: Array as PropType<Call[]>,
      required: true,
    },
  })

  type MixedAlertCall = ((Alert | Call) & {type: string})

  const filters = useStorage(callHistoryFilterStorageKey, defaultCallHistoryFilter)
  const calls = toRef(props.calls)

  const activeFilterCount = computed(() => filters.value.callTypes.length + filters.value.users.length + filters.value.types.length)

  const filteredCalls = computed(() => {
    return calls.value.filter((call: Call) => {
      let matchesCallType = true
      let matchesUser = true

      if (filters.value.callTypes.length) {
        matchesCallType = filters.value.callTypes.includes(call.call_type_text)
      }

      if (filters.value.users.length) {
        if (!call.accepted_by_username) {
          matchesUser = false
        } else {
          matchesUser = filters.value.users.includes(call.accepted_by_username)
        }
      }

      return matchesCallType && matchesUser
    })
  })

  const closedCallsFilterModalVisible = ref(false)

  const combinedData = computed(() => {
    let alerts: MixedAlertCall[] = []
    let calls: MixedAlertCall[] = []

    if (filters.value.types.length === 0 || filters.value.types.includes('alerts')) {
      alerts = props.alerts.map((alert) => ({ ...alert, type: 'alert' }))
    }

    if (filters.value.types.length === 0 || filters.value.types.includes('calls')) {
      calls = filteredCalls.value.map((call: Call) => ({ ...call, type: 'call' }))
    }

    return [...alerts, ...calls].sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    })
  })

  const { data: paginatedCombinedData, hasMore, next, reset: resetPagination } = useSimplePagination(combinedData, 16)

  function applyFilter (filter: CallListFilter) {
    filters.value = filter
    resetPagination()
    closedCallsFilterModalVisible.value = false
  }

  function clearFilter () {
    filters.value = defaultCallHistoryFilter
    resetPagination()
    closedCallsFilterModalVisible.value = false
  }
</script>

<template>
  <section class="space-y-4">
    <SectionHeading>{{ $t('history.title') }}</SectionHeading>

    <div class="flex justify-center">
      <button
        v-wave
        class="flex items-center bg-gray-200 text-gray-700 rounded-full text-2xs px-4 py-1 my-2"
        :class="activeFilterCount > 0 ? '!bg-yellow-100 !text-yellow-800' : ''"
        @click="closedCallsFilterModalVisible = true"
      >
        <MdiIcon :icon="mdiFilter" width="14" class="mr-2" />
        <template v-if="activeFilterCount > 0">
          {{ $t('history.filter.count', { count: activeFilterCount }) }}
        </template>
        <template v-else>
          {{ $t('history.filter.action') }}
        </template>
      </button>
    </div>

    <div v-if="paginatedCombinedData.length > 0" class="space-y-2">
      <template v-for="item in paginatedCombinedData" :key="`${item.type}-${item.id}`">
        <transition-group name="collapse">
          <template v-if="item.type === 'alert'">
            <AlertListItem
              :alert="item as Alert"
              :readOnly="true"
            />
          </template>
          <template v-else-if="item.type === 'call'">
            <CallListItem
              :call="item as Call"
              :readOnly="true"
            />
          </template>
        </transition-group>
      </template>

      <div v-if="hasMore" class="mx-4">
        <TextButton class="w-full !mt-6" @click="next">
          {{ $t('common.actions.load_more') }}
        </TextButton>
      </div>
    </div>

    <EmptyState v-else :text="$t('history.empty')" />

    <CallListFilterModal
      :visible="closedCallsFilterModalVisible"
      :calls="calls"
      :initialFilter="filters"
      @apply="applyFilter"
      @clear="clearFilter"
    />
  </section>
</template>