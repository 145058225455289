import { Action } from 'vuex'
import { State } from '@/store/index'
import { RouteLocationNormalized } from 'vue-router'

interface SnackbarState {
  message: string
  action?: string
  actionFn?: (...args: any[]) => void
  dismissable?: boolean
}

interface Timeouts {
  escalation: number
  resend: number
}

// CallListViewMode are possible call view filters on the main screen.
export enum CallListViewMode {
  All = 'all',
  Priority = 'priority',
  History = 'history',
  Team = 'team',
  Own = 'own',
  New = 'new',
  Presences = 'presence',
}

type TrackedRouteState = Pick<RouteLocationNormalized, 'name' | 'params' | 'meta'>

const timeoutsStorageKey = 'cs.app.timeouts'

export enum PromptPermissionsMode {
  DoNotPrompt,
  Prompt,
  ForcePrompt,
}

export interface UIState {
  snackbars: Record<string, SnackbarState>
  callListViewMode: CallListViewMode
  currentRoute: TrackedRouteState,
  timeouts: Timeouts,
  websocketConnected: boolean,
  websocketConnectionError: string,
  lastDemoReset: number,
  debug: boolean,
  processingCallQueue: boolean
  // promptPermissions displays a permission prompt on native platforms.
  // The value is only set once per session and never reset until the user restarts the app.
  promptPermissions: PromptPermissionsMode
}

const state: UIState = {
  callListViewMode: CallListViewMode.Priority,
  currentRoute: {
    name: '',
    params: {},
    meta: {},
  },
  debug: false,
  snackbars: {},
  timeouts: getCachedTimeouts(),
  websocketConnected: false,
  websocketConnectionError: '',
  lastDemoReset: 0,
  processingCallQueue: false,
  promptPermissions: PromptPermissionsMode.DoNotPrompt,
}

// getCachedTimeouts returns cached timeout options from local storage.
function getCachedTimeouts (): Timeouts {
  const defaultValue = { escalation: 600, resend: 120 }
  try {
    const data = localStorage.getItem(timeoutsStorageKey)
    if (!data) {
      return defaultValue
    }
    return JSON.parse(data)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    return defaultValue
  }
}

const getters = {}

const actions: Record<string, Action<UIState, State>> = {

  showSnackbar ({ commit }, payload: { message: string, action: string, timeout: number, actionFn: (...args: any[]) => void, dismissable?: boolean }) {
    const key = (+Date.now().toString() + '_' + Math.round((Math.random() * 1000)).toString())
    commit('addSnackbar', {
      key,
      state: {
        message: payload.message,
        action: payload.action,
        actionFn: payload.actionFn,
        dismissable: payload.dismissable ?? true,
      }
    })

    if (payload.timeout > 0) {
      setTimeout(() => commit('resetSnackbar', key), payload.timeout)
    }

    return () => {
      commit('resetSnackbar', key)
    }
  }

}

const mutations = {
  addSnackbar (state: UIState, payload: { key: string, state: SnackbarState }) {
    state.snackbars[payload.key] = payload.state
  },
  resetSnackbar (state: UIState, key: string) {
    delete state.snackbars[key]
  },
  setCallListViewMode (state: UIState, view: CallListViewMode) {
    state.callListViewMode = view
  },
  setCurrentRoute (state: UIState, payload: TrackedRouteState) {
    state.currentRoute = payload
  },
  setWebsocketConnected (state: UIState, connected: boolean) {
    state.websocketConnected = connected
  },
  setWebsocketConnectionErrorMessage (state: UIState, message: string) {
    state.websocketConnectionError = message
  },
  setDebug (state: UIState, debug: boolean) {
    state.debug = debug
  },
  setTimeouts (state: UIState, payload: Timeouts) {
    if (!payload) {
      return
    }
    state.timeouts = payload
    localStorage.setItem(timeoutsStorageKey, JSON.stringify(payload))
  },
  setProcessingCallQueue (state: UIState, value: boolean) {
    state.processingCallQueue = value
  },
  promptPermissions (state: UIState, mode: PromptPermissionsMode = PromptPermissionsMode.Prompt) {
    state.promptPermissions = mode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}