<script setup lang="ts">
  import { useRouter } from 'vue-router'
  import SwipeToAction from '@/components/ui/SwipeToAction.vue'
  import { mdiAlert, mdiBellCheckOutline, mdiLifebuoy } from '@mdi/js'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { computed, onMounted, ref } from 'vue'
  import { Haptics, NotificationType } from '@capacitor/haptics'
  import { ifNative } from '@/capacitor'
  import { cancelSOS, triggerSOS } from '@/api/requests'
  import ContainedButton from '@/components/material/ContainedButton.vue'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { useNow } from '@vueuse/core'
  import { useStore } from '@/store'
  import { useI18n } from 'vue-i18n'
  import { logError } from '@/common/utils'
  import { AxiosError } from 'axios'

  const store = useStore()
  const router = useRouter()
  const i18n = useI18n()

  const cancelSOSTimeoutSeconds = 30

  const countdown = ref(3)
  const error = ref('')
  const loading = ref(false)
  const retries = ref(0)
  const succeeded = ref(false)
  const triggeredAt = ref<Date | null>(null)
  const count = ref(0)

  const now = useNow()

  const secondsSinceTriggered = computed(() => {
    if (!triggeredAt.value) {
      return 0
    }

    return Math.floor((now.value.getTime() - triggeredAt.value.getTime()) / 1000)
  })

  let countDownInterval: number | undefined

  onMounted(() => {
    window.clearInterval(countDownInterval)
    countDownInterval = window.setInterval(() => {
      countdown.value--
      if (countdown.value === 0) {
        send()
        window.clearInterval(countDownInterval)
      }
    }, 1000)

    ifNative(() => {
      Haptics.vibrate({ duration: 200 })
    })
  })

  const retryFn = ref(send)

  async function send (isRetry = false) {
    error.value = ''
    loading.value = true
    retryFn.value = send
    count.value = 0

    if (!isRetry) {
      retries.value = 0
    }

    try {
      const response = await triggerSOS()
      count.value = response.notified.length
    } catch (e) {
      // A 424 status means there are no users to notify.
      if ((e as AxiosError)?.response?.status === 424) {
        loading.value = false
        error.value = i18n.t('sos.errors.no_users')
        return
      }

      if (retries.value < 3) {
        retries.value++
        window.setTimeout(() => send(true), 1000)
        return
      }

      logError('failed to send SOS', e)

      error.value = i18n.t('sos.errors.send')
      loading.value = false
      return
    }

    loading.value = false
    retries.value = 0
    succeeded.value = true
    triggeredAt.value = new Date()

    ifNative(() => {
      Haptics.vibrate({ duration: 800 })
    })
  }

  async function cancel () {
    loading.value = false
    error.value = ''
    retryFn.value = cancel
    count.value = 0

    try {
      await cancelSOS()
    } catch (e) {
      error.value = i18n.t('sos.errors.cancel')
      succeeded.value = false

      logError('failed to cancel SOS', e)

      return
    } finally {
      loading.value = false
    }

    await store.dispatch('ui/showSnackbar', {
      message: i18n.t('sos.notifications.cancelled'),
      timeout: 5000,
    })

    ifNative(() => {
      Haptics.notification({ type: NotificationType.Success })
    })

    await close()
  }

  async function close () {
    window.clearInterval(countDownInterval)
    await router.push({ name: 'callList' })
  }
</script>

<template>
  <div class="bg-gray-100 overflow-y-auto h-screen flex flex-col">
    <div
      class="border-[20px] flex-1 flex items-center justify-center"
      :class="{'border-animation': !succeeded, 'border-transparent': succeeded}"
    >
      <div v-if="!loading" class="p-5 flex flex-col space-y-4 text-center">
        <template v-if="succeeded">
          <div class="mx-auto text-csBlue-500">
            <MdiIcon
              :icon="mdiBellCheckOutline"
              :size="64"
            />
          </div>
          <div class="space-y-4">
            <h1 class="text-3xl font-bold leading-tight uppercase tracking-wide">
              {{ $t('sos.triggered') }}
            </h1>

            <p v-if="count > 0" class="text-gray-800">
              {{ $t('sos.people_notified', { count }) }}
            </p>

            <SwipeToAction v-if="secondsSinceTriggered < cancelSOSTimeoutSeconds" class="!mt-16" @swiped="cancel">
              {{ $t('sos.swipe_to_cancel') }}
            </SwipeToAction>

            <TextButton
              class="!mt-4 normal-case mx-auto"
              :class="{'bg-csBlue-500 !mt-8 uppercase text-white px-8': secondsSinceTriggered >= cancelSOSTimeoutSeconds}"
              @click="close()"
            >
              {{ $t('common.actions.close') }}
            </TextButton>
          </div>
        </template>
        <template v-else>
          <div class="mx-auto text-csBlue-500" :class="{'text-csBlue-500': !error, 'text-orange-500': error}">
            <MdiIcon
              :icon="error ? mdiAlert : mdiLifebuoy"
              :size="64"
            />
          </div>
          <div class="space-y-4 text-3xl">
            <h1 class="text-4xl font-bold leading-tight uppercase tracking-wide">
              {{ error ? $t('common.states.error') : 'SOS' }}
            </h1>

            <div v-if="!error" class="font-semibold leading-tight">
              <div>
                {{ $t('sos.countdown', { count: countdown }) }}
              </div>

              <SwipeToAction class="!mt-16" @swiped="close()">
                {{ $t('common.actions.swipe_to_cancel') }}
              </SwipeToAction>
            </div>

            <div v-else>
              <p class="text-sm my-8">
                {{ error }}
              </p>
              <ContainedButton class="bg-orange-500 w-full mt-4" @click="retryFn">
                {{ $t('common.actions.retry') }}
              </ContainedButton>
              <TextButton
                class="!mt-4 normal-case mx-auto"
                @click="close()"
              >
                {{ $t('common.actions.close') }}
              </TextButton>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <CircularProgress />
      </div>
    </div>
  </div>
</template>

<style scoped>
  .border-animation {
    animation: border-alarm 1s infinite;
  }

  @keyframes border-alarm {
    0%, 100% {
      border-color: theme('colors.orange.300');
    }

    50% {
      border-color: theme('colors.orange.400');
    }
  }
</style>