<script setup lang="ts">
  import { mdiArrowRight } from '@mdi/js'
  import { useSwipeGesture } from '@/common/swipe.api'
  import { computed, Ref, ref } from 'vue'

  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { MaybeComputedElementRef, useElementSize } from '@vueuse/core'

  const emit = defineEmits(['swiped', 'click'])
  const props = defineProps({
    swipeLabel: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  })

  const item = ref(null)

  const { width: containerWidth } = useElementSize(item as unknown as MaybeComputedElementRef)

  // swipeThreshold defines how many px an item has to be swiped until it gets removed.
  const swipeThreshold = computed(() => containerWidth.value / 100 * 66) // 66 % of container width

  const {
    onPointerDown: onPointerDownSwipe,
    indicatorStyle,
    style,
    swipedPercentage,
    reset,
    x
  } = useSwipeGesture(() => emit('swiped'), swipeThreshold, containerWidth as unknown as Ref<number>)

  function onPointerDown (e: MouseEvent) {
    if (!props.readOnly) {
      onPointerDownSwipe.value(e)
    }
  }

  function onPointerUp (e: MouseEvent) {
    reset()

    // Don't modify click actions on the primary action button.
    if ((e.target as HTMLElement).closest('button')) {
      return
    }

    e.preventDefault()

    // If the swipe was less than 5px, we assume it was a click.
    if (x.value < 5) {
      emit('click')
    }
  }
</script>

<template>
  <div
    ref="item"
    class="swipeable-item container relative z-10"
    :class="{'is-readonly': readOnly}"
  >
    <!-- Swipe Action -->
    <div class="absolute inset-0 z-0">
      <div
        class="mx-4 rounded absolute inset-0 bg-yellow-200 text-yellow-800 flex items-center justify-start pl-3 font-semibold text uppercase"
        :style="{ '--tw-bg-opacity': swipedPercentage * 0.01 }"
      >
        <div class="flex items-center justify-start transition-opacity duration-700" :style="indicatorStyle">
          <span class="inline-block mr-2">
            {{ swipeLabel }}
          </span>
          <MdiIcon :icon="mdiArrowRight" />
        </div>
      </div>
    </div>

    <!-- Content -->
    <div
      class="relative select-none inset-0 z-10 transition-opacity duration-300"
      :style="style"
      @pointerdown="onPointerDown"
      @pointerup="onPointerUp"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped>
  .swipeable-item:not(.is-readonly) {
    cursor: grab;
    touch-action: pan-y;
  }
</style>