<script setup lang="ts">

  import { computed } from 'vue'

  const props = defineProps({
    online: {
      type: Boolean,
      default: true,
    },
    available: {
      type: Boolean,
      default: true,
    },
    handlingCall: {
      type: Boolean,
      default: false,
    }
  })

  const showOverlay = computed(() => props.handlingCall && props.online)
</script>

<template>
  <div class="relative">
    <div
      class="rounded-full flex-shrink-0 w-3 h-3"
      :class="{
        'bg-gray-500': !online,
        'bg-green-500': online && available,
        'bg-red-600': online && !available,
        'circle-clipped': showOverlay,
      }"
    />
    <div
      v-if="showOverlay"
      class="absolute circle-overlay inset-0 rounded-full flex-shrink-0 w-3 h-3"
      :class="{
        'bg-orange-400': handlingCall,
      }"
    />
  </div>
</template>

<style>
  .circle-clipped {
    clip-path: polygon(0 0, 0 100%, 100% 0);
  }
  .circle-overlay {
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
  }
</style>