import { loginClient, logoutUser as logoutUserRequest } from '@/api/requests'
import { WebSocket } from '@/capacitor'
import { State } from '@/store'
import { Store } from 'vuex'
import { CallListViewMode } from '@/store/ui.state'
import { Router } from 'vue-router'
import { Composer } from 'vue-i18n'
import { computed, Ref } from 'vue'
import { User } from '@/types'
import { differenceInMinutes, isAfter, parseISO } from '@/common/time'

// MutedUntilCharging is the muted until date that is set when a device is charging.
export const MutedUntilCharging = '2038-01-01T00:00:00Z'

export async function loginUser (store: Store<State>, i18n: Composer, router: Router, username: string, password: string, groupId: number | null = null) {
  const response = await loginClient({
    username,
    password,
    client_id: store.state.client.client.id,
    group_id: groupId
  })

  const message = response.user.name
    ? i18n.t('login.states.logged_in_as', { name: response.user.name })
    : i18n.t('login.states.logged_in')

  const clearSnackbar = await store.dispatch('ui/showSnackbar', { message })
  window.setTimeout(clearSnackbar, 5000)

  store.commit('app/setFeatures', response.features)
  store.commit('app/setLicense', response.license)
  await store.dispatch('user/setUserAndGroup', {
    user: response.user,
    group: response.group
  })
  await store.dispatch('client/setClient', {
    ...response.client,
    session_id: response.session_id,
    app_user_group_id: response.group.id
  })

  await WebSocket.startService()

  store.commit('ui/promptPermissions')

  // Enforce subscriptions check after login.
  if (store.state.app.features.subscriptions_confirmation_required && !store.state.app.features.display_names) {
    await router.push({ name: 'subscription' })

    await store.dispatch('ui/showSnackbar', {
      message: i18n.t('subscription.snackbars.subscription_required'),
      timeout: 5000,
    })
  } else {
    await router.push({ name: 'callList' })
  }
}

export async function logoutUser (store: Store<State>) {
  await logoutUserRequest()

  await store.dispatch('client/logoutClient')
  await store.dispatch('user/clearUser')
  store.commit('ui/setCallListViewMode', CallListViewMode.Priority)

  await WebSocket.stopService()
}

// useUserState returns different properties that are useful for determining a user's state.
export function useUserState (time: Ref<{ now: number }>, user: Ref<User>) {
  const isMuted = computed(() => {
    if (!user.value.muted_until) {
      return false
    }
    return isAfter(parseISO(user.value.muted_until), time.value.now)
  })

  const isCharging = computed(() => {
    if (!user.value.muted_until) {
      return false
    }
    return user.value.muted_until === MutedUntilCharging
  })

  const isInCall = computed(() => {
    if (!user.value.in_call_since) {
      return false
    }
    return differenceInMinutes(time.value.now, parseISO(user.value.in_call_since)) < 15
  })

  const isAvailable = computed(() => {
    return !isMuted.value && !isInCall.value
  })

  return { isMuted, isCharging, isInCall, isAvailable }
}

