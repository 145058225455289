import { Capacitor, registerPlugin } from '@capacitor/core'
import { WebSocketPlugin } from '@/capacitor/WebsocketPlugin'
import { StatePlugin } from '@/capacitor/StatePlugin'
import { EmergencyNotificationPlugin } from '@/capacitor/EmergencyNotificationPlugin'
import { WifiManagerPlugin } from '@/capacitor/WifiManagerPlugin'
import { PermissionsPlugin } from '@/capacitor/PermissionsPlugin'

// Plugins
export const State = registerPlugin<StatePlugin>('State', {
  web: () => import('./StatePlugin').then(m => new m.StateWeb())
})

export const WebSocket = registerPlugin<WebSocketPlugin>('WebSocket', {
  web: () => import('./WebsocketPlugin').then(m => new m.WebsocketWeb()),
})

export const EmergencyNotification = registerPlugin<EmergencyNotificationPlugin>('EmergencyNotification', {
  web: () => import('./EmergencyNotificationPlugin').then(m => new m.EmergencyNotificationWeb())
})

export const WifiManager = registerPlugin<WifiManagerPlugin>('WifiManager', {
  web: () => import('./WifiManagerPlugin').then(m => new m.WifiManagerWeb())
})

export const Permissions = registerPlugin<PermissionsPlugin>('Permissions', {
  web: () => import('./PermissionsPlugin').then(m => new m.PermissionsWeb())
})

// ifNative executes a callback function only if the app is running on a native platform.
export function ifNative (fn: CallableFunction) {
  if (Capacitor.isNativePlatform()) {
    fn()
  }
}

// ifWeb executes a callback function only if the app is running on a web platform.
export function ifWeb (fn: CallableFunction) {
  if (!Capacitor.isNativePlatform()) {
    fn()
  }
}

// ifNativeElse executes a callback function if the app is running on a native platform.
// A separate callback can be provided if the app is running on the web platform.
export function ifNativeElse (fn: CallableFunction, elseFn: CallableFunction) {
  if (Capacitor.isNativePlatform()) {
    fn()
  } else {
    elseFn()
  }
}