import { Action } from 'vuex'
import { State } from '@/store/index'
import { Alert } from '@/types'
import { confirmAlert, getAlerts } from '@/api/requests'

export interface AlertState {
  alerts: Alert[]
  confirmedAlerts: Record<number, string>
}

const state: AlertState = {
  alerts: [],
  confirmedAlerts: {}
}

const getters = {
  byId: (state: AlertState) => (id: number) => {
    return state.alerts.find(c => c.id === id)
  },
  latestCriticalAlert: (state: AlertState) => {
    const latest = state.alerts[0] ?? null
    if (latest?.class?.toLowerCase() !== 'critical' || latest?.closed_at !== null) {
      return null
    }
    return latest
  },
  latestCriticalNotConfirmedAlert: (state: AlertState, getters: any) => {
    const latest = getters.latestCriticalAlert
    if (!latest || state.confirmedAlerts.hasOwnProperty(latest.id)) {
      return null
    }
    return latest
  },
  // Alerts that are relevant for the current user.
  relevantAlerts: (state: AlertState) => {
    return state.alerts.filter(
      a => a.closed_at === null && !state.confirmedAlerts.hasOwnProperty(a.id)
    ) ?? []
  },
  sortedRelevantAlerts: (state: AlertState, getters: any) => {
    const severity: Record<string, number> = {
      'critical': 1,
      'alert': 2,
      'info': 3,
    }
    return [...getters.relevantAlerts].sort((a, b) => {
      if (a.closed_at || b.closed_at) {
        return 1
      }
      if (a.class === b.class) {
        return b.id - a.id
      }
      return severity[a.class.toLowerCase()] - severity[b.class.toLowerCase()]
    })
  },
  sortedConfirmedAlerts: (state: AlertState) => {
    return [...state.alerts].filter(a => a.closed_at !== null || state.confirmedAlerts.hasOwnProperty(a.id)).sort((a, b) => {
      return (+new Date(b.created_at ?? 0)) - (+new Date(a.created_at ?? 0))
    })
  },
}

const actions: Record<string, Action<AlertState, State>> = {
  async confirmAlert ({ commit }, alert: Alert) {
    const response = await confirmAlert(alert.id)
    commit('confirmAlert', alert)
    return response.alert
  },
  async fetch ({ commit }) {
    const response = await getAlerts()
    if (response && response.alerts) {
      commit('setAlerts', response.alerts)
    }
    if (response && response.confirmed) {
      commit('setConfirmedAlerts', response.confirmed)
    }
  },
}

const mutations = {
  setAlerts (state: AlertState, alerts: Alert[]) {
    state.alerts = [...alerts]
  },
  setConfirmedAlerts (state: AlertState, confirmed: Record<number, string>) {
    state.confirmedAlerts = { ...confirmed }
  },
  confirmAlert (state: AlertState, alert: Alert) {
    state.confirmedAlerts = { ...state.confirmedAlerts, [alert.id]: new Date().toISOString() }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}