import { checkInClientThrottled } from '@/api/requests'
import { logInfo } from '@/common/utils'
import { State } from '@/store'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Router } from 'vue-router'
import { Store } from 'vuex'
import { useConfig } from '@/config'

export function setupCapacitorApp (router: Router, store: Store<State>) {
  // Handle the initial URL of the app. This allows us to link to the app from a browser or from a notification.
  App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
    logInfo('app opened via url', event)
    const slug = event.url.split('ch.caresuite.app://').pop()
    if (slug) {
      await router.push({ path: `/${slug}` })
    }
  })
  App.addListener('appStateChange', async ({ isActive }) => {
    // Fetch the latest state from the backend when the app is activated.
    const config = await useConfig()
    if (isActive && config.backend && router.currentRoute.value.name !== 'login') {
      const response = await checkInClientThrottled({ }, router, store)

      logInfo('Client check-in successful', response)
    }
  })
}