<template>
  <header class="px-6 mt-6">
    <slot />
  </header>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DrawerHeader',
    setup () {
    }
  })
</script>
