<script setup lang="ts">
  import { PropType, ref, watch } from 'vue'
  import { useStore } from '@/store'
  import Modal from '@/components/modal/Modal.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { useI18n } from 'vue-i18n'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { mdiAlert } from '@mdi/js'
  import CircularProgress from '@/components/material/CircularProgress.vue'
  import { Call } from '@/types'

  const store = useStore()
  const i18n = useI18n()

  const loading = ref(false)
  const error = ref<any | null>(null)

  const emit = defineEmits(['close'])
  const props = defineProps({
    visible: {
      type: Boolean,
      default: false,
    },
    call: {
      type: [Object, null] as PropType<Call|null>,
      default: null,
    }
  })

  watch(() => props.call, () => {
    if (!props.call?.callback_number) {
      emit('close')
    }
  })

  async function initiateCall () {
    loading.value = true
    error.value = null

    try {
      await store.dispatch('call/initiateCallback', props.call)

      await store.dispatch('ui/showSnackbar', {
        message: i18n.t('calllist.snackbars.callback_success'),
        timeout: 5000,
      })

      emit('close')
    } catch (ex: any) {
      error.value = ex
    } finally {
      loading.value = false
    }
  }
</script>

<template>
  <Modal :visible="visible" :title="$t('calllist.modals.callback.title')" @close="$emit('close')">
    <div v-if="loading" class="w-full flex items-center justify-center min-h-[126px]">
      <CircularProgress class="size-8" />
    </div>
    <div v-else class="mb-4 text-sm space-y-4">
      <i18n-t keypath="calllist.modals.callback.text_1">
        <strong>{{ call?.callback_number }}</strong>
      </i18n-t>

      <p>
        {{ $t('calllist.modals.callback.text_2') }}
      </p>

      <div v-if="error !== null" class="bg-yellow-100 p-2 rounded text-yellow-800 text-sm">
        <div class="flex items-start gap-4">
          <MdiIcon :icon="mdiAlert" class="size-5" />
          <div>
            <strong>
              {{ $t('calllist.modals.callback.error') }}
            </strong>
            <div class="mt-2 text-2xs">
              {{ error }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #actions>
      <TextButton v-if="!loading" class="bg-csBlue-800 text-white w-full mb-2" @click="initiateCall">
        {{ $t('calllist.modals.callback.title') }}
      </TextButton>
    </template>
  </Modal>
</template>