<template>
  <hr class="border-t border-gray-100">
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'DrawerSeparator',
  })
</script>
