<script setup lang="ts">
  import { mdiPhone } from '@mdi/js'
  import { computed, PropType } from 'vue'
  import { UserClientCall } from '@/types'
  import { useTime } from '@/common/time'
  import TeamMemberStatus from '@/components/user/TeamMemberStatus.vue'
  import MdiIcon from '@/components/ui/MdiIcon.vue'
  import { useI18n } from 'vue-i18n'
  import { useUserState } from '@/common/user.api'

  enum Status {
    Idle = 'Idle',
    Busy = 'Busy',
    Present = 'Present',
  }

  const props = defineProps({
    data: {
      type: Object as PropType<UserClientCall>,
      required: true,
    }
  })

  const initials = computed(() => {
    const name = props.data.user.current_display_name || props.data.user.name
    if (!name) {
      return ''
    }
    return name.split(' ').slice(0, 2).map(word => word[0]).join('')
  })

  const displayName = computed(() => {
    return props.data.user.current_display_name ? props.data.user.current_display_name : props.data.user.name
  })

  const i18n = useI18n()
  const time = useTime()

  const user = computed(() => props.data.user)

  const { isMuted, isCharging, isInCall, isAvailable } = useUserState(time, user)

  const stateString = computed(() => {
    let availabilityStatus = ''
    let callStatus = ''

    if (!props.data.client.is_connected) {
      return 'Offline'
    } else if (isMuted.value) {
      if (isCharging.value) {
        availabilityStatus = i18n.t('people.states.charging')
      } else {
        availabilityStatus = i18n.t('people.states.muted')
      }
      // If the user is not handling a call, return just the availability status.
      if (!props.data.call.text_short) {
        return availabilityStatus
      }
    } else if (isInCall.value) {
      availabilityStatus = i18n.t('people.states.in_call')
      // If the user is not handling a call, return just the availability status.
      if (!props.data.call.text_short) {
        return availabilityStatus
      }
    }

    switch (props.data.user.status) {
    case Status.Idle:
      callStatus = i18n.t('people.states.idle')
      break
    case Status.Busy:
      callStatus = i18n.t('people.states.busy')
      break
    case Status.Present:
      callStatus = i18n.t('people.states.present')
      break
    }

    if (availabilityStatus.length > 0) {
      availabilityStatus += ', ' + callStatus.toLowerCase()
    } else {
      availabilityStatus = callStatus
    }

    if (props.data.call.text_short) {
      if (props.data.user.status === Status.Busy) {
        return availabilityStatus + ' ' + i18n.t('people.on_the_way_to', { target: props.data.call.text_short })
      }
      return availabilityStatus + ' ' + i18n.t('people.present_at', { target: props.data.call.text_short })
    }

    return availabilityStatus
  })
</script>

<template>
  <div class="bg-white flex items-center text-gray-800">
    <div class="min-w-0 flex-1 flex gap-4 items-center p-2">
      <div class="flex-shrink-0">
        <div class="leading-none rounded-full w-12 h-12 bg-gray-100 flex items-center justify-center">
          {{ initials }}
        </div>
      </div>
      <div class="flex-1">
        <div class="font-semibold truncate">
          {{ displayName }}
        </div>
        <div class="leading-none flex gap-2 items-center mt-1">
          <TeamMemberStatus
            :online="data.client.is_connected"
            :available="isAvailable"
            :handlingCall="data.user.status === Status.Busy || data.user.status === Status.Present"
          />
          <div class="text-xs text-gray-800">
            {{ stateString }}
          </div>
        </div>
      </div>
      <div class="flex-shrink-0">
        <div v-if="data.client.phone_number" class="flex gap-2 items-center">
          <a
            v-wave
            :href="`tel:${data.client.phone_number}`"
            class="rounded-full cursor-pointer hover:bg-gray-100 w-12 h-12 flex items-center justify-center"
          >
            <MdiIcon :icon="mdiPhone" class="w-8" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>