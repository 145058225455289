<script setup lang="ts">
  import { onMounted, ref, watch } from 'vue'
  import { Permissions, State } from '@/capacitor'
  import Modal from '@/components/modal/Modal.vue'
  import TextButton from '@/components/material/TextButton.vue'
  import { useDocumentVisibility } from '@vueuse/core'
  import { Capacitor } from '@capacitor/core'

  // Enforce the check every time the visibility changes. This is required to run the check again
  // after the user returns from the settings screen.
  const visibility = useDocumentVisibility()
  watch(visibility, () => {
    if (visibility.value === 'visible') {
      checkPermissions()
    }
  })

  onMounted(() => {
    checkPermissions()
  })

  const visible = ref(false)

  async function checkPermissions () {
    const { isRunningTests } = await State.isRunningTests()

    if (!Capacitor.isNativePlatform() || isRunningTests) {
      return
    }

    // Handle the special DND permissions.
    const result = await Permissions.granted({
      permission: 'android.permission.ACCESS_NOTIFICATION_POLICY',
    })

    const hasDndPermission = result?.granted

    if (!hasDndPermission) {
      visible.value = true
    }
  }

  async function goToDndSettings () {
    await Permissions.prompt({
      permission: 'android.permission.ACCESS_NOTIFICATION_POLICY',
    })
    visible.value = false
  }
</script>

<template>
  <Modal :visible="visible" :title="$t('common.modals.dnd_permission.title')" @close="() => {}">
    <div class="space-y-2">
      <p>
        {{ $t('common.modals.dnd_permission.text_1') }}
      </p>
      <p>
        {{ $t('common.modals.dnd_permission.text_2') }}
      </p>
    </div>
    <template #actions>
      <TextButton @click="goToDndSettings">
        {{ $t('common.modals.dnd_permission.action') }}
      </TextButton>
    </template>
  </Modal>
</template>