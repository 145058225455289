<script setup lang="ts">
  import { computed, PropType } from 'vue'
  import { ValidationErrorBag } from '@/common/errors'

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    inputmode: {
      type: String as PropType<any>,
      required: false,
      default: '',
    },
    inputClass: {
      type: [String, Array],
      default: () => [],
    },
    errors: {
      type: Object as PropType<ValidationErrorBag>,
      default: () => ({})
    }
  })

  defineEmits<{
    (event: 'update:modelValue', value: string): void
  }>()

  const error = computed(() => {
    if (props.errors[props.id]) {
      return props.errors[props.id]
    }
    return ''
  })
</script>

<template>
  <div class="relative">
    <div
      v-if="label"
      class="absolute top-0 translate-y-1/2 text-gray-400 transition duration-200 ease-out"
      :class="{'!translate-y-0 text-2xs': modelValue }"
    >
      <label :for="id">{{ label }}</label>
    </div>
    <div class="relative">
      <input
        :id="id"
        autocomplete="off"
        class="bg-transparent border-b border-gray-500 w-full pt-4 pb-1 focus:outline-none"
        :inputmode="inputmode"
        :class="[error ? '!border-red-500' : '', inputClass]"
        :value="modelValue"
        @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      >
      <slot name="input" />
    </div>
    <div v-if="error" class="text-red-500 text-xs mt-1">
      {{ error }}
    </div>
  </div>
</template>
