import { computed, ref, Ref } from 'vue'

export function useSimplePagination (list: Ref<any[]>, perPage: number) {
  const offset = ref(0)
  const realOffset = computed(() => perPage + offset.value)
  const hasMore = computed(() => realOffset.value < list.value.length)
  const data = computed(() => {
    if (!list.value.length) {
      return []
    }

    return list.value.slice(0, realOffset.value)
  })

  function next () {
    offset.value += perPage
  }

  function reset () {
    offset.value = 0
  }

  return {
    data,
    next,
    hasMore,
    reset,
  }
}