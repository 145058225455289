export interface CallListFilter {
  callTypes: string[]
  users: string[]
  types: string[]
}

export const callHistoryFilterStorageKey = 'cs.app.calllist.filter.history'

export const defaultCallHistoryFilter: CallListFilter = {
  callTypes: [],
  users: [],
  types: [],
}

