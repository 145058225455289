import { useDrag, useSpring } from 'vue-use-gesture'
import { computed, ref, Ref } from 'vue'
import { CallActionEvent } from '@/common/call.api'

export interface ActionConfig {
  button: {
    event: CallActionEvent | ''
    label: string
    icon: string
  },
  swipe: {
    event: CallActionEvent | ''
    label: string
  }
}

export function useSwipeGesture (swipeCallback: CallableFunction, threshold: Ref<number>, containerWidth: Ref<number>) {
  // @ts-ignore // this is a bug in type definition of library.
  const [{ x }, set] = useSpring(() => ({ x: 0 }))

  const swipedAway = ref(false)

  let callbackTimeout: number | undefined = undefined

  // @ts-ignore
  const bind = useDrag(({ active, movement: [mx] }) => {
    // Do nothing once the element was swiped away.
    if (swipedAway.value) {
      return
    }

    // Do nothing when swiping left.
    if (mx < 0) {
      return
    }

    // If the threshold has been reached, move the item all the way to the right.
    if (mx > threshold.value) {
      set({ x: containerWidth.value })
      swipedAway.value = true

      window.clearTimeout(callbackTimeout)
      callbackTimeout = window.setTimeout(swipeCallback, 400) // Allow the transition to end

      return
    }

    set({ x: active ? mx : 0, immediate: active })
  }, { axis: 'x' })

  // transform the element when swiping.
  const style = computed(() => ({ transform: `translate3d(${x.value}px,0,0)`, opacity: swipedAway.value ? 0 : 1 }))
  // move the indicator text to the right while swiping, but slower than the wipe.
  const indicatorStyle = computed(() => ({ opacity: swipedAway.value ? 0 : 1, transform: `translate3d(${x.value * .05}px,0,0)` }))
  // The number of percentage the swipe gesture is applied.
  const swipedPercentage = computed(() => Math.min(100 / threshold.value * x.value, 100))
  // onPointerDown event handler.
  const onPointerDown = computed(() => bind().onPointerDown)

  function reset () {
    set({ x: 0, immediate: true })
    swipedAway.value = false
  }

  return {
    onPointerDown,
    indicatorStyle,
    style,
    reset,
    swipedPercentage,
    swipedAway,
    x,
  }
}