<script setup lang="ts">

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps({
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Array,
      required: true,
    },
  })

  function onChange () {
    const newValue = [...props.modelValue]

    if (newValue.includes(props.value)) {
      newValue.splice(newValue.indexOf(props.value), 1)
    } else {
      newValue.push(props.value)
    }
    emit('update:modelValue', newValue)
  }
</script>

<template>
  <div>
    <input
      :id="`checkbox-${value}`"
      type="checkbox"
      class="hidden"
      :checked="modelValue.includes(value)"
      @change="onChange"
    >
    <label v-wave class="flex items-center py-2 v-wave px-4 -mx-4 rounded-lg select-none" :for="`checkbox-${value}`">
      <span class="flex-grow flex items-center">
        <span
          class="check-icon h-4 w-4 flex items-center justify-center rounded-sm border-csBlue-700 border-2 relative"
        >
          <svg
            class="absolute h-2 left-0 text-gray-50 fill-current ml-px"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4.44912 7.88963L1.13019 4.78001L0 5.83147L4.44912 10L14 1.05145L12.8778 0L4.44912 7.88963Z" />
          </svg>
        </span>
        <span class="ml-4">
          <span class="font-semibold text-csBlue-800 leading-none">
            {{ label ?? value }}
          </span>
        </span>
      </span>
    </label>
  </div>
</template>

<style lang="stylus" scoped>
  [type="checkbox"]:checked + label
    & .check-icon
      @apply bg-csBlue-500
</style>
