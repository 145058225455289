<script setup lang="ts">
  import TextButton from '@/components/material/TextButton.vue'
  import Modal from '@/components/modal/Modal.vue'
  import { computed, PropType, ref, watch } from 'vue'
  import { Call } from '@/types'
  import FormCheckbox from '@/components/material/FormCheckbox.vue'
  import { CallListFilter, defaultCallHistoryFilter } from '@/common/filter.api'
  import { RemovableRef } from '@vueuse/core'

  const emit = defineEmits(['apply', 'clear'])
  const props = defineProps({
    calls: {
      type: Array as PropType<Call[]>,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: true,
    },
    initialFilter: {
      type: Object as PropType<RemovableRef<CallListFilter>>,
      default: () => defaultCallHistoryFilter,
    },
  })

  const filter = ref<CallListFilter>(defaultCallHistoryFilter)

  watch(() => props.initialFilter, newFilter => {
    filter.value = { ...newFilter } as unknown as CallListFilter
  }, {
    immediate: true,
  })

  // Make sure that active filter values are always displayed as a checkbox. It is possible to have
  // an old filter value that is no longer present in the current set of calls.
  const userFilters = computed(() => {
    const users = [...props.calls.map(call => call.accepted_by_username), ...filter.value.users]
    return [...new Set(users)].filter(i => !!i) // unique, non-empty
  })

  const callTypeFilters = computed(() => {
    const types = [...props.calls.map(call => call.call_type_text), ...filter.value.callTypes]
    return [...new Set(types)].filter(i => !!i) // unique, non-empty
  })

  function apply () {
    emit('apply', { ...filter.value })
  }
</script>

<template>
  <Modal :title="$t('calllist.modals.filter.title')" :visible="visible">
    <div class="max-h-[50vh] space-y-6">
      <div>
        <p class="text-csBlue-800 font-semibold mb-1">
          {{ $t('common.events') }}
        </p>

        <FormCheckbox v-model="filter.types" :value="'calls'" :label="$t('common.calls')" />

        <FormCheckbox v-model="filter.types" :value="'alerts'" :label="$t('common.alerts')" />
      </div>

      <div v-if="callTypeFilters.length">
        <p class="text-csBlue-800 font-semibold mb-1">
          {{ $t('common.call_types') }}
        </p>
        <FormCheckbox
          v-for="value in callTypeFilters"
          :key="value"
          v-model="filter.callTypes"
          :value="value"
        />
      </div>

      <div v-if="userFilters.length">
        <p class="text-csBlue-800 font-semibold mb-1">
          {{ $t('common.done_by') }}
        </p>
        <template v-for="value in userFilters" :key="value">
          <FormCheckbox
            v-if="value !== null"
            v-model="filter.users"
            :value="value"
          />
        </template>
      </div>
    </div>
    <template #actions>
      <TextButton class="text-csBlue-800 ml-3" @click="$emit('clear')">
        {{ $t('common.actions.empty_filter') }}
      </TextButton>
      <TextButton class="text-csBlue-800" @click="apply">
        {{ $t('common.actions.apply') }}
      </TextButton>
    </template>
  </Modal>
</template>
